import { useEffect, useState, useMemo } from 'react'
import {
  Text,
  Box,
  FormControl,
  Button,
  TextInput,
  ActionMenu,
  ActionList,
  Spinner,
} from '@primer/react'
import { Link } from 'react-router-dom'
import { getPublicTests } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { TestCard } from '@/components/test-card'

export function TestPublicLibrary() {
  const [testsData, setTestsData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [visibleTests, setVisibleTests] = useState(10)
  const [sortCriteria, setSortCriteria] = useState('recently_created')

  const fetchTests = async () => {
    try {
      const response = await getPublicTests()
      if (response.error) {
        throw new Error(response.msg)
      }
      setTestsData(response.data)
      // console.log("response.data-tpl", response.data)
      setLoading(false)
    } catch (err) {
      setError(err.message || 'Failed to fetch tests')
      setLoading(false)
    }
  }

  const sortedAndFilteredTests = useMemo(() => {
    if (!testsData || !testsData.tests) return []
    
    // console.log("testsData", testsData)
    let filteredTests = testsData.tests.filter(testItem =>
      testItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    
    return filteredTests.sort((a, b) => {
      switch (sortCriteria) {
        case 'recently_created':
          return new Date(b.created_at) - new Date(a.created_at)
        case 'least_recently_created':
          return new Date(a.created_at) - new Date(b.created_at)
        case 'recently_updated':
          return new Date(b.updated_at) - new Date(a.updated_at)
        case 'least_recently_updated':
          return new Date(a.updated_at) - new Date(b.updated_at)
        default:
          return 0
      }
    })
  }, [testsData, searchTerm, sortCriteria])

  const loadMore = () => {
    setVisibleTests(prevVisible => prevVisible + 10)
  }

  useEffect(() => {
    fetchTests()
  }, [])

  const handleSort = (criteria) => {
    setSortCriteria(criteria)
  }

  const getSortText = () => {
    switch (sortCriteria) {
      case 'recently_created':
        return 'Recently created'
      case 'least_recently_created':
        return 'Least recently created'
      case 'recently_updated':
        return 'Recently updated'
      case 'least_recently_updated':
        return 'Least recently updated'
      default:
        return 'Recently created'
    }
  }

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Spinner />
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            color: 'fg.muted'
          }}>
            <Icons.Alert /> <Text sx={{ color: 'fg.muted' }}>Failed to fetch tests</Text>
          </Box>
          <Button variant='primary' onClick={() => fetchTests()}>Please try again</Button>
        </Box>
      ) : !testsData.tests || testsData?.tests.length === 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Text sx={{ color: 'fg.muted', textAlign: 'center' }}>Public library is empty. Please check back later.</Text>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Link style={{ textDecoration: 'none' }} to='/dashboard/tests?tab=create'><Button sx={{ textDecoration: 'none' }} variant='primary'>Create a new test</Button></Link>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormControl.Label visuallyHidden>Search tests</FormControl.Label>
              <TextInput
                size='large'
                placeholder='Search tests'
                sx={{ width: '100%' }}
                onChange={(e) => setSearchTerm(e.target.value)}
                leadingVisual={Icons.SearchIcon}
              />
            </FormControl>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', marginTop: 2 }}>
              <ActionMenu>
                <ActionMenu.Button>
                  <Text sx={{ color: 'fg.muted' }}>Sort:</Text>{' '}
                  <Text>{getSortText()}</Text>
                </ActionMenu.Button>
                <ActionMenu.Overlay width='small'>
                  <ActionList selectionVariant='single'>
                    <ActionList.Item selected={sortCriteria === 'recently_created'} onSelect={() => handleSort('recently_created')}>
                      Recently created
                    </ActionList.Item>
                    <ActionList.Item selected={sortCriteria === 'least_recently_created'} onSelect={() => handleSort('least_recently_created')}>
                      Least recently created
                    </ActionList.Item>
                    <ActionList.Item selected={sortCriteria === 'recently_updated'} onSelect={() => handleSort('recently_updated')}>
                      Recently updated
                    </ActionList.Item>
                    <ActionList.Item selected={sortCriteria === 'least_recently_updated'} onSelect={() => handleSort('least_recently_updated')}>
                      Least recently updated
                    </ActionList.Item>
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>
            </Box>
            {sortedAndFilteredTests.slice(0, visibleTests).map((testItem) => (
              <TestCard key={testItem.id} test={testItem} />
            ))}
            {visibleTests < sortedAndFilteredTests.length && (
              <Button onClick={loadMore} sx={{ mt: 3, alignSelf: 'center' }}>
                Load More
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}