/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { API_ROUTE } from '@/lib/constants'
import { axiosGetWithRetry } from '@/lib/utils'

// Context creation
const AuthContext = createContext()

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext)

// Provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [needsProjectChange, setNeedsProjectChange] = useState(false)

  // Function to check current authenticated user
  const currentAuthenticatedUser = async () => {
    try {
      const response = await axiosGetWithRetry(`${API_ROUTE}/users/me`, {
        withCredentials: true,
      })
      if (!response.data.data.current_project) {
        console.log(
          'Please contact your administrator to assign you to an organization.'
        )
        setNeedsProjectChange(true)
      } else {
        setNeedsProjectChange(false)
      }

      return { loaded: true, user: response.data.data }
    } catch (err) {
      console.error('Error during authentication', err)
      return { loaded: false, user: null }
    }
  }

  // Function to handle authentication failure
  const handleAuthFailure = () => {
    console.log('Session expired or user is not authenticated.')
    signout()
  }

  // Function to start periodic check of authentication status
  const startAuthCheck = () => {
    if (Cookies.get('session_id') === undefined) {
      return
    }
    console.log('starting authcheck in authcontext')
    const authCheckInterval = setInterval(async () => {
      // console.log('Checking authentication status...')
      const authStatus = await currentAuthenticatedUser()
      if (!authStatus.loaded || authStatus.user == null) {
        handleAuthFailure()
      } else {
        // Update user state if there's a successful check to keep user info updated.
        setUser(authStatus.user)
        setIsAuthenticated(true)
      }
    }, 20000) // Check every 10 seconds, adjust timing as needed

    return authCheckInterval
  }

  // Sign in function
  const signin = async () => {
    setIsLoading(true)
    const authData = await currentAuthenticatedUser()
    if (authData.loaded && authData.user) {
      setIsAuthenticated(true)
      setUser(authData.user)
      startAuthCheck()
    } else {
      setIsAuthenticated(false)
      setUser(null)
      handleAuthFailure()
    }

    setIsLoading(false)
  }

  // Sign out function
  const signout = () => {
    if (Cookies.get('session_id') !== undefined) {
      try {
        Cookies.remove('session_id', {
          path: '/',
          domain: '.intrudergroup.com',
        })
      } catch (error) {
        console.error('Error removing session_id cookie', error)
      }

      try {
        Cookies.remove('session_id')
      } catch (error) {
        console.error('Error removing session_id cookie dev', error)
      }

      window.location.replace('/login')
    }
    setIsAuthenticated(false)
    setUser(null)
    window.location.replace('/login') // Optionally use a more React-friendly navigation method
  }

  // Use effect hook to perform sign in on mount
  useEffect(() => {
    signin()
    const intervalId = startAuthCheck() // Start auth check and keep the interval ID

    return () => clearInterval(intervalId) // Cleanup interval on component unmount
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        signin,
        signout,
        isLoading,
        needsProjectChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
