import { useEffect, useCallback, useRef, useState } from 'react'
import {
  ActionList,
  ActionMenu,
  Avatar,
  Text,
  Box,
  Truncate,
  Dialog,
  Button,
  FormControl,
  TextInput,
  Spinner,
} from '@primer/react'
import { Icons } from '@/components/icons'
import { getMe, getProjects, swapProject, createProject } from '@/lib/calls'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function ChangeProjectPage() {
  const [user, setUser] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const buttonRef = useRef()
  const onDialogClose = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false)
    }
  }, [isLoading])

  const handleSwapProject = async projectId => {
    // alert(projectId)
    try {
      setIsLoading(true)
      await swapProject(projectId, true)
      toast.custom(t => (
        <CustomToast message='Project changed' type='success' />
      ))
      window.location.href = '/dashboard'
      setIsLoading(false)
      // Handle success - e.g., show a success message
    } catch (error) {
      setIsLoading(false)
      // Handle errors - e.g., show error message
      toast.custom(t => (
        <CustomToast message='Error changing project' type='error' />
      ))

      console.error('Error changing project:', error)
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getMe()
        // alert(JSON.stringify(response.data))
        setUser(response.data)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching user', error)
      }
    }

    const fetchProjects = async () => {
      try {
        setIsLoadingProjects(true)
        const response = await getProjects()
        setProjects(response.data)
      } catch (error) {
        console.error('Error fetching projects', error)
      } finally {
        setIsLoadingProjects(false)
      }
    }
    fetchProjects()

    fetchUser()
  }, [])

  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const [isLoadingProjects, setIsLoadingProjects] = useState(false)
  const [projects, setProjects] = useState([])

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleSubmit = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        await createProject(value)
        toast.custom(t => (
          <CustomToast message='Project created' type='success' />
        ))
        setIsLoading(false)
        // Handle success - e.g., show a success message
        onDialogClose() // Close the dialog if successful
      } catch (error) {
        setIsLoading(false)

        toast.custom(t => (
          <CustomToast message='Error creating new project' type='error' />
        ))

        // console.log(error);
        console.error('Error creating project', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (value.length > 255) {
      setValidationResult('tooLong')
    } else if (value.length < 3) {
      setValidationResult('tooShort')
    } else {
      setValidationResult('validName')
    }
  }, [value])

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'canvas.overlay',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Spinner size='medium' />
        </Box>
      )}
      <ActionMenu>
        <ActionMenu.Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '195px', // Adjust the width as needed
            }}
          >
            {user && user.project_id && user.current_project ? (
              <>
                <Avatar
                  size='14'
                  src={`https://avatar.vercel.sh/${user.project_id}.png`}
                  sx={{ flexShrink: 0 }}
                />
                <Box sx={{ mx: 2, flex: '1 1 auto', minWidth: 0 }}>
                  <Truncate title={user.current_project.name}>
                    {user.current_project.name}
                  </Truncate>
                </Box>
                <Text
                  sx={{
                    fontSize: 0,
                    color: 'fg.muted',
                    flexShrink: 0,
                  }}
                >
                  {user.current_project.owner_id === user.id ? 'Owner' : 'User'}
                </Text>
              </>
            ) : (
              <>
                <Box sx={{ mx: 6, flex: '1 1 auto', minWidth: 0 }}>
                  <Truncate title='Choose project'>Select project</Truncate>
                </Box>
              </>
            )}
          </Box>
        </ActionMenu.Button>
        <ActionMenu.Overlay width='medium'>
          <ActionList role='menu' showDividers aria-label='Projects'>
            <ActionList.Group title='Projects' selectionVariant='single'>
              {/* <pre>{JSON.stringify(authProvider, null, 2)}</pre> */}
              {isLoadingProjects && !user && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner size='small' />
                </Box>
              )}
              {user &&
                !isLoadingProjects &&
                projects.map(project => (
                  <ActionList.Item
                    key={project.id}
                    selected={project.id === user.project_id}
                    onSelect={() => {
                      handleSwapProject(project.id)
                      setIsLoading(true)
                      // alert(JSON.stringify(projects))
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        size='24'
                        src={`https://avatar.vercel.sh/${project.id}.png`}
                      />
                      <Box sx={{ mx: 2, flex: '1 1 auto', minWidth: 0 }}>
                        <Truncate title={project.name}>{project.name}</Truncate>
                      </Box>

                      <Text
                        sx={{
                          fontSize: 0,
                          color: 'fg.muted',
                          flexShrink: 0,
                          mr: 2,
                        }}
                      >
                        {project.owner_id === user.user.id ? 'Owner' : 'User'}
                      </Text>
                    </Box>
                  </ActionList.Item>
                ))}
              <ActionList.Divider />
              <ActionList.Item onClick={() => setIsOpen(true)}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Icons.Add size='24' />
                  <Text>Create new project</Text>
                </Box>
              </ActionList.Item>
            </ActionList.Group>
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onDismiss={onDialogClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Create new project</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' color='fg.muted'>
              A new project will help you collaborate and manage tests content
              in another isolated environment.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'tooShort' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be less than 3 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a name (3 to 255 characters).
                </FormControl.Caption>
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmit()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={handleSubmit} variant="primary">Submit</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
