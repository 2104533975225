import { useState, useEffect, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Box, Label, Breadcrumbs, Text, Spinner, FormControl, Button, TextInput, Select, ActionList } from '@primer/react'
import { SelectPanel } from '@primer/react/drafts' 
import {Icons} from '@/components/icons'
import { createChain, patchChain, getTests, getChain, getTeamservers } from '@/lib/calls'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export default function ChainsEditor({ chainId }) {
    const buttonRef = useRef(null)
    const title = <Text sx={{ color: 'fg.default' }}>Select tests</Text>

    const [isTestsLoading, setIsTestsLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
  
    const searchParams = new URLSearchParams(location.search)
    const isEditMode = searchParams.get('tab') === 'edit'
    const [chain, setChain] = useState({ name: '', teamserver_id: '', tests: [] })
    const [teamservers, setTeamservers] = useState([])

    const [isOpen, setIsOpen] = useState(false)
    const [selectedTestIds, setSelectedTestIds] = useState([])
    const [tests, setTests] = useState([])

    const resetState = useCallback(() => {
        setChain({ name: '', teamserver_id: '', tests: [] })
        setSelectedTestIds([])
        setTests([])
        setFilteredTests([])
        setQuery('')
    }, [])


    const fetchChain = useCallback(async () => {
      if (isEditMode && chainId) {
          try {
              const response = await getChain(chainId)
              setChain(response.data.chain)
              setSelectedTestIds(response.data.chain.tests || [])
          } catch (err) {
              // Handle error
          }
      } else {
          resetState()
      }
  }, [isEditMode, chainId, resetState])

    const fetchTeamservers = useCallback(async () => {
        try {
            const response = await getTeamservers()
            if (response.error) {
                throw new Error(response.msg)
            }
            setTeamservers(response.data.teamservers)
        } catch (error) {
            console.error('Error fetching teamservers', error)
        }
    }, [])
    
    // useEffect(() => {
    //     fetchChain()
    //     fetchTeamservers()
    // }, [fetchChain, fetchTeamservers, chainId])

    const fetchAllTests = useCallback(async () => {
      setIsTestsLoading(true)
      try {
          const response = await getTests()
          if (response.error) {
              throw new Error(response.msg)
          }
          setTests(response.data.tests)
          setFilteredTests(response.data.tests)
      } catch (error) {
          setFetchError(error)
          console.error('Error fetching tests', error)
      } finally {
          setIsTestsLoading(false)
      }
  }, [])

    useEffect(() => {
      fetchChain()
      fetchTeamservers()
      fetchAllTests()
  }, [fetchChain, fetchTeamservers, fetchAllTests])
  
  
    
      useEffect(() => {
        if (chain.tests) {
          setSelectedTestIds(chain.tests)
        }
      }, [chain])

      useEffect(() => {
        if (chainId) {
          // Fetch chain data for editing
          fetchChain()
        } else {
          // Reset state for creating a new chain
          setChain({ name: '', teamserver_id: '', tests: [] })
          setSelectedTestIds([])
        }
      }, [chainId, fetchChain])  

    const [fetchError, setFetchError] = useState(null)
    // const [itemsToShow, setItemsToShow] = useState([])
    const notFound = <Text sx={{ color: 'fg.default' }}>No tests found</Text>


    const handleOpen = async () => {
        setIsOpen(!isOpen)
        setIsTestsLoading(true)
        try {
          const response = await getTests()
          if (response.error) {
            throw new Error(response.msg)
          }
          setTests(response.data.tests)
        } catch (error) {
          setFetchError(error)
          console.error('Error fetching tests', error)
        } finally {
          setIsTestsLoading(false)
        }
      }

    /* Selection */
    const onTestSelect = testId => {
        if (!selectedTestIds.includes(testId))
        setSelectedTestIds([...selectedTestIds, testId])
        else setSelectedTestIds(selectedTestIds.filter(id => id !== testId))
    }
    const onClearSelection = () => {
        setSelectedTestIds([])
    }

    const onSubmit = () => {
        setIsOpen(!isOpen)
      }
    
    /* Filtering */
  const [filteredTests, setFilteredTests] = useState(tests)
  const [query, setQuery] = useState('')

  const onSearchInputChange = event => {
    const query = event.currentTarget.value
    setQuery(query)

    if (!tests) return

    if (query === '') setFilteredTests(tests)
    else {
      setFilteredTests(
        tests
          .map(test => {
            if (test.name.toLowerCase().startsWith(query))
              return { priority: 1, test }
            else if (test.name.toLowerCase().includes(query))
              return { priority: 2, test }
            else return { priority: -1, test }
          })
          .filter(result => result.priority > 0)
          .map(result => result.test)
      )
    }
  }

  const itemsToShow = query 
    ? filteredTests 
    : tests
    
    // console.log("itemsToShow", itemsToShow)

    const [isLoading, setIsLoading] = useState(false)
    const handleSubmit = async () => {
        // console.log("chain", chain)
        // console.log("selectedTestIds", selectedTestIds)
        setIsLoading(true)
        // setError(null)
        try {
          
              if (isEditMode) {
                await patchChain(chainId, chain.name, chain.teamserver_id, selectedTestIds)
              } else {
                await createChain(chain.name, chain.teamserver_id, selectedTestIds)
              }
              toast.custom(t => (
                <CustomToast message={isEditMode ? 'Chain updated' : 'Chain created'} type='success' />
              ))
            
          navigate('/dashboard/chains')
        } catch (err) {
          toast.custom(t => (
            <CustomToast message={err.response.data.msg} type='error' />
          ))
        } finally {
          setIsLoading(false)
        }
      }


    return (
        <Box sx={{
            width: '100%',
        }}>
          <Breadcrumbs sx={{ paddingLeft: 2, marginBottom: 3, marginTop: 3 }}>
            <Link to='/dashboard/chains'><Breadcrumbs.Item href='#'>Chains</Breadcrumbs.Item></Link>
            <Breadcrumbs.Item href='#' selected sx={{ color: 'accent.fg', fontWeight: 'bold' }}>
              {isEditMode ? 'Edit test' : 'Create new test'}
            </Breadcrumbs.Item>
          </Breadcrumbs>

          {!teamservers && (
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '1px solid',
                          borderColor: 'border.default',
                          borderRadius: 2,
                          height: '200px',
                      }}>
                          <Text fontWeight="bold" fontSize={4}>Teamserver required</Text>
                          <Text color="fg.muted">You need at least one teamserver. Deploy a teamserver to get started.</Text>
                      </Box>
          )}

            {teamservers && (
              <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput
        value={chain.name}
    onChange={(e) => setChain({ ...chain, name: e.target.value })}
    sx={{
        width: '100%',
    }}
    />
            </FormControl>
            <FormControl required>
                <FormControl.Label>Teamserver</FormControl.Label>
                {!teamservers && <Text sx={{ color: 'danger.fg' }}>No teamservers found. You need to add one first.</Text>}
                {teamservers && (
                <Select
                    value={chain.teamserver_id}
                onChange={(e) => setChain({ ...chain, teamserver_id: e.target.value })}
                sx={{ width: '100%' }}
                >
                <Select.Option value="">Select a teamserver</Select.Option>
                {teamservers.map((teamserver) => (
                    <Select.Option key={teamserver.id} value={teamserver.id}>
                    {teamserver.os} - Port: {teamserver.listening_port}
                    </Select.Option>
                ))}
                </Select>
                )}
            </FormControl>
            <FormControl required id='autocompleteLabel'>
                <FormControl.Label>Tests</FormControl.Label>
                {tests && (
                        <>
                       <Button
                            variant='default'
                            ref={buttonRef}
                            trailingAction={() => (
                              <Label variant='accent'>
                                <Icons.Test
                                  sx={{
                                    width: 14,
                                    height: 14,
                                    mr: 1,
                                  }}
                                />
                                {selectedTestIds.length}
                              </Label>
                            )}
                            onClick={() => {
                              handleOpen()
                            }}
                            sx={{
                              width: '100%',
                              '[data-component=buttonContent]': {
                                justifyContent: 'start',
                              },
                            }}
                          >
                            Select tests 
                          </Button>

                          <SelectPanel
  title={title}
  onSubmit={onSubmit}
  open={isOpen}
  variant='anchored'
  anchorRef={buttonRef}
  onCancel={() => {
    setIsOpen(!isOpen)
  }}
  onClearSelection={onClearSelection}
>
  <SelectPanel.Header>
    <SelectPanel.SearchInput
      onChange={onSearchInputChange}
    />
  </SelectPanel.Header>

  {isTestsLoading ? (
    <Box
      sx={{
        display: 'flex',
        color: 'fg.default',
        justifyContent: 'center',
        alignItems: 'center',
        height: 200,
      }}
    >
      <Spinner />
    </Box>
  ) : itemsToShow.length === 0 ? (
    <SelectPanel.Message
      variant='empty'
      title={notFound}
    >
      Try a different search term
    </SelectPanel.Message>
  ) : (
    <ActionList>
      {itemsToShow.map(test => (
        <ActionList.Item
          key={test.id}
          onSelect={() => onTestSelect(test.id)}
          selected={selectedTestIds.includes(test.id)}
        >
          <ActionList.LeadingVisual>
            <Box
              sx={{
                width: 14,
                height: 14,
                borderRadius: '100%',
              }}
              style={{
                backgroundColor: `#${test.color}`,
              }}
            />
          </ActionList.LeadingVisual>
          {test.name.length > 25
            ? `${test.name.slice(0, 25)}...`
            : test.name}
        </ActionList.Item>
      ))}
    </ActionList>
  )}

  {fetchError && (
    <SelectPanel.Message
      variant='error'
      title='Error fetching tests'
    >
      Unable to fetch tests
    </SelectPanel.Message>
  )}
</SelectPanel>
                        </>
                      )}
                    </FormControl>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'end', marginTop: 3 }}>
              <Button variant='primary' onClick={handleSubmit} disabled={isLoading || !teamservers}>
                {isEditMode ? 'Update' : 'Submit'}
              </Button>
            </Box>
            </>
            )}
        </Box>
    )
}