import { useState, useEffect } from 'react'
import { Box, Text, Spinner } from '@primer/react'
import { getChains } from '@/lib/calls'
import ChainCard from '@/components/chain-card'

export default function ChainsLibrary({ refreshTrigger, onChainDeleted }) {
    const [isLoading, setIsLoading] = useState(true)
    const [chains, setChains] = useState([])
    const [error, setError] = useState(null)

    const fetchChains = async () => {
        try {
            const chains = await getChains()
            console.log(chains)
            setChains(chains)
        } catch (e) {
            setError(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchChains()

        const intervalId = setInterval(() => {
            fetchChains()
        }, 10000) // Fetch every 30 seconds

        return () => clearInterval(intervalId) // Cleanup on unmount
    }, [refreshTrigger])

    return (
        <>
        {isLoading && (
            <Box sx={{
                display: 'flex',
                
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2,
                height: '200px',
            }}>
                <Spinner />
                
            </Box>
            )}
            
            {!error &&!isLoading && chains && chains.data.chains && chains.data.chains.length === 0 && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2,
                height: '200px',
            }}>
                <Text fontWeight="bold" fontSize={4}>There are no chains in this project</Text>
                <Text color="fg.muted">Create a new chain to deploy your tests to teamservers agents.</Text>
            </Box>
            )}

            {error && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2,
                height: '150px',
            }}>
                <Text fontWeight="bold" fontSize={4}>An error occured</Text>
                <Text color="fg.muted">Please try again later.</Text>
            </Box>
            )}

            {!error && !isLoading && chains && chains.data.chains && chains.data.chains.length > 0 && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    marginTop: 3,
                }}>
                    {chains.data.chains.map((chain) => (
                        <ChainCard key={chain.id} chain={chain} onChainDeleted={onChainDeleted} />
                    ))}
                </Box>
            )}
        </>
    )

}