import { Box, PageHeader, Spinner, Text, FormControl, TextInput, Button } from '@primer/react'
import LogCard from '@/components/log-card'
import { getLogs, deleteExecutionLogs } from '@/lib/calls'
import { useEffect, useState, useMemo } from 'react'
import { Icons } from '@/components/icons'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function LogsPage() {
  const [refetchLogs, setRefetchLogs] = useState(false)
  const [logs, setLogs] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [visibleLogs, setVisibleLogs] = useState(10)

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await getLogs()
        setLogs(response.data)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    // Initial fetch
    fetchLogs()

    // Set up periodic re-fetching
    const intervalId = setInterval(fetchLogs, 30000) // Re-fetch every 30 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId)
  }, [refetchLogs])

  const filteredLogs = useMemo(() => {
    if (!logs || !logs.execution_logs) return []
    
    return Object.values(logs.execution_logs).filter(session =>
      session.chain_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      session.execution_logs.some(log => 
        log.test_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.test_description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  }, [logs, searchTerm])

  const loadMore = () => {
    setVisibleLogs(prevVisible => prevVisible + 10)
  }

  const clearLogs = async () => {
    try {
      await deleteExecutionLogs()
      toast.custom(t => <CustomToast message='Logs cleared' type='success' />)
      setRefetchLogs(!refetchLogs)
    } catch (e) {
      toast.custom(t => <CustomToast message='Could not clear logs' type='error' />) 
      console.error(e)
    }
  }

  const handleClearLogs = () => {
    clearLogs()
  }

  return (
    <>
      <Box sx={{ padding: 3 }}>
        <PageHeader>
          <PageHeader.TitleArea>
            <PageHeader.Title as="h2">Logs</PageHeader.Title>
          </PageHeader.TitleArea>
          <PageHeader.Actions>
            <Button onClick={handleClearLogs}>Clear logs</Button>
          </PageHeader.Actions>
        </PageHeader>
      </Box>
      <Box>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner />
          </Box>
        )}
        {error && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid',
            borderColor: 'border.default',
            borderRadius: 2,
            height: '150px',
          }}>
            <Text fontWeight="bold" fontSize={4}>An error occurred</Text>
            <Text color="fg.muted">Please try again later.</Text>
          </Box>
        )}
        {!error && !loading && logs && (
          Object.keys(logs.execution_logs).length === 0 ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid',
              borderColor: 'border.default',
              borderRadius: 2,
              height: '200px',
            }}>
              <Text fontWeight="bold" fontSize={4}>There are no logs in this project</Text>
              <Text color="fg.muted">Logs are created when you run a test.</Text>
            </Box>
          ) : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              // padding: 3,
            }}>
              <FormControl>
                <FormControl.Label visuallyHidden>Filter logs</FormControl.Label>
                <TextInput
                  size='large'
                  placeholder='Search logs'
                  sx={{ width: '100%' }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  leadingVisual={Icons.SearchIcon}
                />
              </FormControl>
              {filteredLogs.slice(0, visibleLogs).map(session => (
                <LogCard key={session.session_id} session={session} />
              ))}
              {visibleLogs < filteredLogs.length && (
                <Button onClick={loadMore} sx={{ mt: 3, alignSelf: 'center' }}>
                  Load More
                </Button>
              )}
            </Box>
          )
        )}
      </Box>
    </>
  )
}