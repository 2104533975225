import React, { useState } from 'react'
import { Box, Text, Link, IconButton } from '@primer/react'
import Logo from '@/components/ui/logo'
import { Icons } from '@/components/icons'

export function Footer() {
  const [colorMode, setColorMode] = useState(
    localStorage.getItem('themePreference') || 'day'
  )

  // Toggle theme preference
  const toggleTheme = () => {
    const newTheme = colorMode === 'day' ? 'night' : 'day'

    localStorage.setItem('themePreference', newTheme)

    setColorMode(newTheme)

    window.location.reload()

    // const newTheme = colorMode === 'day' ? 'night' : 'day'
    // setColorMode(newTheme)
    // localStorage.setItem('themePreference', newTheme)
    // document.body.style.backgroundColor =
    //   newTheme === 'night'
    //     ? theme.colorSchemes.dark.colors.canvas.subtle
    //     : theme.colorSchemes.light.colors.canvas.subtle
  }

  return (
    <Box
      sx={{
        // backgroundColor: 'danger.fg',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {/* <Text>Logo</Text> */}
      <Link href='/'>
        <Logo variant={colorMode === 'day' ? 'color' : 'white'} size={20} />
      </Link>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          fontSize: 12,
          color: 'fg.muted',
        }}
      >
        {/* <Link to="#" >FAQ</Link> */}
        {/* <Link to="#" >API</Link> */}
        <Text>app.intrudergroup.com v1.0.0</Text>
        <IconButton
          icon={colorMode === 'day' ? Icons.Sun : Icons.Moon}
          variant='invisible'
          aria-label={`Change color mode. Active mode is ${colorMode}.`}
          onClick={() => toggleTheme()}
        />
      </Box>
    </Box>
  )
}
