/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useRef, useState } from 'react'
import {
  Text,
  Box,
  FormControl,
  TextInput,
  Button,
  Dialog,
  Tooltip,
  ToggleSwitch,
  IconButton,
  PageHeader,
  ActionMenu,
  ActionList,
  Spinner,
} from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { DataTable, Table } from '@primer/react/drafts'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'
import { createRole, updateRole, deleteRole, getRoles } from '@/lib/calls'
import { Icons } from '@/components/icons'
import { useAuth } from '@/contexts/AuthContext'

export function RolesPage() {
  const { user } = useAuth()

  const [roles, setRoles] = useState([])

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const buttonRef = useRef()
  const onDialogClose = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false)
    }
  }, [isLoading])
  const onDialogEditClose = useCallback(() => {
    if (!isLoading) {
      setIsOpenEdit(false)
    }
  }, [isLoading])
  // const onDialogClose = useCallback(() => setIsOpen(false), []);
  // const onDialogEditClose = useCallback(() => setIsOpenEdit(false), []);

  const [editRoleId, setEditRoleId] = useState('')

  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const [readOnly, setReadOnly] = useState(false)
  const [editTests, setEditTests] = useState(false)
  const [editChains, setEditChains] = useState(false)

  const [editReadOnly, setEditReadOnly] = useState(false)
  const [editEditTests, setEditEditTests] = useState(false)
  const [editEditChains, setEditEditChains] = useState(false)

  const [reloadTable, setReloadTable] = useState(false)

  const [isOpenDeleteRole, setIsOpenDeleteRole] = useState(false)
  const onDialogDeleteRoleClose = useCallback(
    () => setIsOpenDeleteRole(false),
    []
  )
  const [deleteRoleId, setDeleteRoleId] = useState('')

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleOpenEdit = row => {
    setEditRoleId(row.id)
    // setEditRoleName(row.name)
    setValue(row.name)
    setValidationResult('validName')
    setEditReadOnly(row.read_only)
    setEditEditTests(row.edit_tests)
    setEditEditChains(row.edit_chains)
    setIsOpenEdit(true)
  }

  const handleOpenDelete = row => {
    setDeleteRoleId(row.id)
    setIsOpenDeleteRole(true)
  }

  const handleOpenAddRole = () => {
    setValue('')
    setIsOpen(true)
  }

  const handleSubmit = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        await createRole(
          user.user.project_id,
          value,
          readOnly,
          editTests,
          editChains
        )
        toast.custom(t => <CustomToast message='Role created' type='success' />)
        setIsLoading(false)
        // Handle success - e.g., show a success message

        onDialogClose() // Close the dialog if successful

        setReloadTable(!reloadTable)
      } catch (error) {
        // Handle errors - e.g., show error message
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error creating new role' type='error' />
        ))

        // console.log(error)
        console.error('Error creating role:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleEdit = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        await updateRole(
          user.user.project_id,
          editRoleId,
          value,
          editReadOnly,
          editEditTests,
          editEditChains
        )
        toast.custom(t => <CustomToast message='Role updated' type='success' />)
        setIsLoading(false)
        // Handle success - e.g., show a success message
        onDialogEditClose() // Close the dialog if successful
        setReloadTable(!reloadTable)
      } catch (error) {
        // Handle errors - e.g., show error message

        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error updating role' type='error' />
        ))

        // console.log(error)
        console.error('Error creating role:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleDeleteRole = async () => {
    try {
      setIsLoading(true)
      await deleteRole(user.user.project_id, deleteRoleId)
      toast.custom(t => <CustomToast message='Role deleted' type='success' />)
      setIsLoading(false)
      // Handle success - e.g., show a success message
      onDialogDeleteRoleClose() // Close the dialog if successful
      setReloadTable(!reloadTable)
    } catch (error) {
      // Handle errors - e.g., show error message
      setIsLoading(false)
      toast.custom(t => (
        <CustomToast message='Error deleting role' type='error' />
      ))

      // console.log(error)
      console.error('Error creating role:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setIsLoading(true)
        // alert(JSON.stringify(user))
        const response = await getRoles(user.user.project_id)
        setRoles(response.data)
      } catch (error) {
        console.error('Error fetching roles', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchRoles()
  }, [])

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setIsLoading(true)
        // alert(JSON.stringify(user))
        const response = await getRoles(user.user.project_id)
        setRoles(response.data)
      } catch (error) {
        console.error('Error fetching roles', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (user) {
      fetchRoles()
    }
  }, [reloadTable])

  useEffect(() => {
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (value.length > 255) {
      setValidationResult('tooLong')
    } else {
      setValidationResult('validName')
    }
  }, [value])

  return (
    <>
      <Box sx={{}}>

      <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Roles & Permissions</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
              Change your organization's roles and permissions
            </PageHeader.Description>
            <PageHeader.Actions>
              <Hidden when={['narrow']}>
              <Button variant='default' onClick={() => handleOpenAddRole()}>
              New role
            </Button>
              </Hidden>
              <Hidden when={['regular', 'wide']}>
              <Button variant='default' onClick={() => handleOpenAddRole()}>
              New
            </Button>
              </Hidden>
            </PageHeader.Actions>
          </PageHeader>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              // width: ['100%', '50%'],
              // height: ['50%', '100%'],
              width: '100%',
              /* backgroundColor: 'green' */
            }}
          >
            {/* Roles & Permissions table goes here   */}
            {/* <pre>{JSON.stringify(localProvider, null, 2)}</pre> */}
            <Table.Container>
              {user && roles && (
                <DataTable
                  aria-labelledby='roles'
                  aria-describedby='roles-subtitle'
                  data={roles}
                  columns={[
                    // {
                    //   header: 'ID',
                    //   field: 'id',
                    //   rowHeader: true
                    // },
                    {
                      header: 'Name',
                      field: 'name',
                      renderCell: row => {
                        return (
                          <Tooltip aria-label={row.name}>
                            <Text>
                              {row.name.length > 25
                                ? `${row.name.slice(0, 25)}...`
                                : row.name}
                            </Text>
                          </Tooltip>
                        )
                      },
                    },
                    {
                      header: 'Project admin',
                      field: 'project_admin',
                      renderCell: row => {
                        return row.project_admin ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Read only',
                      field: 'read_only',
                      renderCell: row => {
                        return row.read_only ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Edit tests',
                      field: 'edit_tests',
                      renderCell: row => {
                        return row.edit_tests ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },

                    {
                      header: 'Edit chains',
                      field: 'edit_chains',
                      renderCell: row => {
                        return row.edit_chains ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },

                    {
                      header: 'Editable',
                      field: 'editable',
                      renderCell: row => {
                        return row.editable ? (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Check}
                            aria-label=''
                          />
                        ) : (
                          <IconButton
                            variant='invisible'
                            icon={Icons.Close}
                            aria-label=''
                          />
                        )
                      },
                    },
                    {
                      header: 'Actions',
                      field: 'actions',
                      renderCell: row => {
                        return (
                          <ActionMenu>
                            <ActionMenu.Anchor>
                              <IconButton
                                variant='invisible'
                                icon={Icons.Menu}
                                aria-label=''
                              />
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay width='medium'>
                              <ActionList>
                                {/* <ActionList.Item onSelect={() => alert('Copy role id clicked')}>
                                Copy role id
                              </ActionList.Item> */}
                                <ActionList.Item
                                  onSelect={
                                    () =>
                                      // setEditRoleId(row.id)
                                      handleOpenEdit(row)
                                    // setIsOpenEdit(true)
                                  }
                                >
                                  Edit role
                                </ActionList.Item>
                                <ActionList.Divider />
                                <ActionList.Item
                                  variant='danger'
                                  onSelect={() => handleOpenDelete(row)}
                                >
                                  Delete role
                                </ActionList.Item>
                              </ActionList>
                            </ActionMenu.Overlay>
                          </ActionMenu>
                        )
                      },
                    },
                  ]}
                />
              )}
            </Table.Container>
            {/* {authProvider.user.current_org.roles.map((role, idx) => (
                <Box key={idx}>{role.name}</Box>
              ))} */}
          </Box>
        </Box>
      </Box>
      {isOpenDeleteRole && (
        <Dialog
          isOpen={isOpenDeleteRole}
          onDismiss={onDialogDeleteRoleClose}
          // returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Are you sure?</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              This action cannot be undone.
            </Text>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                variant='invisible'
                onClick={onDialogDeleteRoleClose}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleDeleteRole()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete role</Text>
                )}
              </Button>
              {/* <Button type="submit" onClick={handleDeleteRole} variant="danger">Delete role</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onDismiss={onDialogClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Create a new role</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              Create a new role and assign permissions.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name is invalid
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a valid role name.
                </FormControl.Caption>
              </FormControl>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Read only
                </Text>
                <ToggleSwitch
                  checked={readOnly}
                  onClick={() => setReadOnly(!readOnly)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Edit tests
                </Text>
                <ToggleSwitch
                  checked={editTests}
                  onClick={() => setEditTests(!editTests)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Edit chains
                </Text>
                <ToggleSwitch
                  checked={editChains}
                  onClick={() => setEditChains(!editChains)}
                  aria-labelledby='toggle'
                />
              </Box>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmit()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => (handleSubmit())} variant="primary">Submit</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
      {isOpenEdit && (
        <Dialog
          isOpen={isOpenEdit}
          onDismiss={onDialogEditClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Update role</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              Update the existing role and assign permissions.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Name</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Name cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Name is invalid
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid name
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a valid role name.
                </FormControl.Caption>
              </FormControl>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Read only
                </Text>
                <ToggleSwitch
                  checked={editReadOnly}
                  onClick={() => setEditReadOnly(!editReadOnly)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Edit tests
                </Text>
                <ToggleSwitch
                  checked={editEditTests}
                  onClick={() => setEditEditTests(!editEditTests)}
                  aria-labelledby='toggle'
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text id='toggle' fontWeight='bold' fontSize={1}>
                  Edit chains
                </Text>
                <ToggleSwitch
                  checked={editEditChains}
                  onClick={() => setEditEditChains(!editEditChains)}
                  aria-labelledby='toggle'
                />
              </Box>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogEditClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleEdit()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => (handleEdit())} variant="primary">Save changes</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
