import {
    Box,
    Label,
    Text,
    Timeline,
    RelativeTime,
    IconButton,
    PageHeader,
  } from '@primer/react'
  import { DataTable, Table } from '@primer/react/drafts'
  import { Icons } from '@/components/icons'
  
  export function DownloadsPage() {

    const data = [
      {
        id: '1',
        name: 'agent_darwin_amd64',
        type: 'Binary',
        os: 'macOS',
        version: '1.0.1',
        arch: 'amd64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/agent_darwin_amd64',
      },
      {
        id: '2',
        name: 'agent_darwin_arm64',
        type: 'Binary',
        os: 'macOS',
        version: '1.0.1',
        arch: 'arm64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/agent_darwin_arm64',
      },
      {
        id: '3',
        name: 'agent_linux_amd64',
        type: 'Binary',
        os: 'Linux',
        version: '1.0.1',
        arch: 'amd64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/agent_linux_amd64',
      },
      {
        id: '4',
        name: 'agent_linux_arm64',
        type: 'Binary',
        os: 'Linux',
        version: '1.0.1',
        arch: 'arm64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/agent_linux_arm64',
      },
      {
        id: '5',
        name: 'agent_windows_amd64.exe',
        type: 'Executable',
        os: 'Windows',
        version: '1.0.1',
        arch: 'amd64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/agent_windows_amd64.exe',
      },
      {
        id: '6',
        name: 'agent_windows_arm64.exe',
        type: 'Executable',
        os: 'Windows',
        version: '1.0.1',
        arch: 'arm64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/agent_windows_arm64.exe',
      },
      {
        id: '7',
        name: 'teamserver_darwin_amd64',
        type: 'Binary',
        os: 'macOS',
        version: '1.0.1',
        arch: 'amd64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/teamserver_darwin_amd64',
      },
      {
        id: '8',
        name: 'teamserver_darwin_arm64',
        type: 'Binary',
        os: 'macOS',
        version: '1.0.1',
        arch: 'arm64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/teamserver_darwin_arm64',
      },
      {
        id: '9',
        name: 'teamserver_linux_amd64',
        type: 'Binary',
        os: 'Linux',
        version: '1.0.1',
        arch: 'amd64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/teamserver_linux_amd64',
      },
      {
        id: '10',
        name: 'teamserver_linux_arm64',
        type: 'Binary',
        os: 'Linux',
        version: '1.0.1',
        arch: 'arm64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/teamserver_linux_arm64',
      },
      {
        id: '11',
        name: 'teamserver_windows_amd64.exe',
        type: 'Executable',
        os: 'Windows',
        version: '1.0.1',
        arch: 'amd64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/teamserver_windows_amd64.exe',
      },
      {
        id: '12',
        name: 'teamserver_windows_arm64.exe',
        type: 'Executable',
        os: 'Windows',
        version: '1.0.1',
        arch: 'arm64',
        releasedAt: '2024-07-30T00:00:00Z',
        downloadUrl: 'https://download.intrudergroup.com/teamserver_windows_arm64.exe',
      },
    ]
    return (
      <>
        <Box sx={{}}>
        <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Downloads</PageHeader.Title>
            </PageHeader.TitleArea>
          </PageHeader>
        </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              width: '100%',
              height: '100%',
              marginTop: 4,
              // backgroundColor: 'canvas.default',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                // backgroundColor: 'green'
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Table.Container>
                  <Table.Title as='h2' id='downloads'>
                    Releases
                  </Table.Title>
                  <Table.Subtitle as='p' id='downloads-subtitle'>
                  </Table.Subtitle>
                  <DataTable
                    aria-labelledby='downloads'
                    aria-describedby='downloads-subtitle'
                    data={data}
                    columns={[
                      {
                        header: 'File',
                        field: 'name',
                        rowHeader: true,
                      },
                      {
                        header: 'Type',
                        field: 'type',
                        renderCell: row => {
                          return <Label>{row.type}</Label>
                        },
                      },
                      {
                        header: 'OS',
                        field: 'os',
                        renderCell: row => {
                          return <Label>{row.os}</Label>
                        },
                      },
                      {
                        header: 'Version',
                        field: 'version',
                      },
                      {
                        header: 'Arch',
                        field: 'arch',
                        renderCell: row => {
                          return <Label>{row.arch}</Label>
                        },
                      },
                      {
                        header: 'Released',
                        field: 'releasedAt',
                        renderCell: row => {
                          return <RelativeTime date={new Date(row.releasedAt)} />
                        },
                      },
                      {
                        header: 'Download',
                        field: 'downloadUrl',
                        renderCell: row => {
                          return (
                            <IconButton
                              as='a'
                              href={!row.disabled ? row.downloadUrl : '#'}
                              variant='invisible'
                              icon={Icons.Download}
                            ></IconButton>
                          )
                        },
                      },
                    ]}
                  />
                </Table.Container>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  // backgroundColor: "danger.emphasis",
                  alignItems: 'start',
                  mt: 4,
                  gap: 3,
                }}
              >
                <Text as='h3'>Changelog</Text>
  
                <Timeline>
                  <Timeline.Item>
                    <Timeline.Badge>
                      <Icons.Zip />
                    </Timeline.Badge>
                    <Timeline.Body>
                      1.0.1 - first release
                    </Timeline.Body>
                  </Timeline.Item>
                </Timeline>
                {/* <LinkButton
                  href='#'
                  variant='invisible'
                >
                  Guides 
                </LinkButton> */}
                <Text as='h3'>Getting started instructions</Text>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  backgroundColor: 'canvas.default',
                  padding: 2,
                  borderRadius: 2,
                  width: '100%',
                  maxWidth: '100%',
                }}>
                  <Text
              sx={{
                // color: 'danger.fg',
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
                  $ ./teamserver_darwin_arm64
                  </Text>

<Text sx={{
   // color: 'danger.fg',
   fontWeight: 'var(--text-codeInline-weight)',
   fontSize: 'var(--text-codeInline-size)',
   fontFamily: 'monospace'
}}>
use --port=lisening_port --token=teamserver_token
                  </Text>


                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  backgroundColor: 'canvas.default',
                  padding: 2,
                  borderRadius: 2,
                  width: '100%',
                  maxWidth: '100%',
                }}>
                  <Text
              sx={{
                // color: 'danger.fg',
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
                  $ ./agent_darwin_arm64

                  </Text>

<Text sx={{
   // color: 'danger.fg',
   fontWeight: 'var(--text-codeInline-weight)',
   fontSize: 'var(--text-codeInline-size)',
   fontFamily: 'monospace'
}}>
use --host=teamserver_host --port=teamserver_port
                  </Text>


                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }