import { Icons } from '@/components/icons'

export const sidebarNavItems = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: Icons.Graph,
  },
  {
    name: 'Tests',
    path: '/dashboard/tests',
    icon: Icons.Test,
  },
  {
    name: 'Chains',
    path: '/dashboard/chains',
    icon: Icons.LinkIcon,
  },
  {
    name: 'Teamservers',
    path: '/dashboard/teamservers',
    icon: Icons.Server,
  },
  {
    name: 'Execution logs',
    path: '/dashboard/execution-logs',
    icon: Icons.Log,
  },
  {
    name: 'Downloads',
    path: '/dashboard/downloads',
    icon: Icons.Download,
  },
]

export const sidebarSettingsNavItems = [
  {
    name: 'Account settings',
    path: '/dashboard/settings/account',
    icon: Icons.Person,
  },
  {
    name: 'Team management',
    path: '/dashboard/settings/team',
    icon: Icons.People,
  },
  {
    name: 'Roles & Permissions',
    path: '/dashboard/settings/roles',
    icon: Icons.Shield,
  },
  {
    name: 'Project settings',
    path: '/dashboard/settings/project',
    icon: Icons.ProjectTemplate,
  },
  {
    name: 'Developers',
    path: '/dashboard/settings/developers/api',
    icon: Icons.Code,
  },
]
