import axios from 'axios'
import Cookies from 'js-cookie'
import { API_ROUTE } from '@/lib/constants'
import { axiosGetWithRetry } from '@/lib/utils'

export async function currentAuthenticatedUser() {
  const defaultReturnObject = { loaded: false, user: null }

  try {
    const response = await axiosGetWithRetry(`${API_ROUTE}/users/me`, {
      withCredentials: true, // to send cookies
    })

    // If the request is successful, Axios will return a response with status 200
    const data = response.data
    return { loaded: true, user: data.data.user } // Adjust according to your API response structure
  } catch (err) {
    // This block will handle any errors including non-200 status codes
    console.error('Error during authentication', err)
    return defaultReturnObject
  }
}

export const authProvider = {
  isAuthenticated: false,
  user: null,
  authCheckInterval: null,

  async signin() {
    const authData = await currentAuthenticatedUser()
    this.isAuthenticated = authData.loaded && authData.user != null
    this.user = authData.user
    this.startAuthCheck() // Start the periodic auth check upon successful signin
  },

  async signout() {
    // Existing signout logic remains the same
    this.stopAuthCheck() // Ensure to stop the auth check when signing out

    try {
      // Attempt to call the logout endpoint
      await axios.get(`${API_ROUTE}/auth/logout`, { withCredentials: true })
    } catch (error) {
      // Handle errors, such as a 401 unauthorized error
      console.error('Error during signout', error)
    }

    // if session_id cookie exists, delete it and refresh the page
    if (Cookies.get('session_id') !== undefined) {
      try {
        Cookies.remove('session_id', {
          path: '/',
          domain: '.intrudergroup.com',
        })
      } catch (error) {
        console.error('Error removing session_id cookie', error)
      }

      try {
        Cookies.remove('session_id')
      } catch (error) {
        console.error('Error removing session_id cookie dev', error)
      }
    }

    // Regardless of the server response, reset the authentication status
    this.isAuthenticated = false
    this.user = null

    window.location.replace('/login')
  },

  startAuthCheck() {
    if (Cookies.get('session_id') === undefined) {
      this.stopAuthCheck()
    } else {
      this.stopAuthCheck() // Stop any existing interval to avoid duplicates
      this.authCheckInterval = setInterval(async () => {
        console.log('ping')
        const authStatus = await currentAuthenticatedUser()
        if (!authStatus.loaded || authStatus.user == null) {
          this.handleAuthFailure()
        }
      }, 10000) // Check every 10 seconds, adjust timing as needed
    }
  },

  stopAuthCheck() {
    if (this.authCheckInterval) {
      clearInterval(this.authCheckInterval)
      this.authCheckInterval = null
    }
  },

  handleAuthFailure() {
    // Handle the case where the user is no longer authenticated
    // This could be redirecting to login, showing a modal, etc.
    console.log('session expired.')
    this.signout() // Optionally sign out the user
    // You might want to redirect to the login page here
  },
}
