import React from 'react'
import { Spinner, Box } from '@primer/react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '@/contexts/AuthContext' // Adjust the path as necessary

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, needsProjectChange } = useAuth()

  if (isLoading) {
    // If we're still loading the user, show a loading message
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Spinner />
      </Box>
    )
  }

  if (isAuthenticated && needsProjectChange && !isLoading) {
    return <Navigate to='/change-project' replace />
  }

  if (!isAuthenticated && !isLoading) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' replace />
  }

  return children ? children : <Navigate to='/dashboard' replace />
}

export { ProtectedRoute }
