import { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, PageHeader } from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import ChainsEditor from '@/components/chains-editor'
import ChainsLibrary  from '@/components/chains-library'

export function ChainsPage() {
    const [tab, setTab] = useState('library')
    const [chainId, setChainId] = useState(null)
    const [refreshTrigger, setRefreshTrigger] = useState(0)
    const location = useLocation()
    const navigate = useNavigate()
  
    useEffect(() => {
      const urlParams = new URLSearchParams(location.search)
      const tabParam = urlParams.get('tab')
      const chainIdParam = urlParams.get('chain_id')
  
      if (tabParam === 'edit' && chainIdParam) {
        setTab('edit')
        setChainId(chainIdParam)
      } else if (
        tabParam === 'library' ||
        tabParam === 'create'
      ) {
        setTab(tabParam)
        setChainId(null)
      } else {
        setTab('library')
        setChainId(null)
      }
    }, [location])
  
    const handleCreateNewChain = useCallback(() => {
      navigate('?tab=create')
      setTab('create')
      setChainId(null)
    }, [navigate])
  
    const handleChainDeleted = useCallback(() => {
      setRefreshTrigger(prev => prev + 1)
    }, [])
  
    const renderContent = () => {
        switch (tab) {
          case 'create':
            return <ChainsEditor key="create" chainId={null} refreshTrigger={refreshTrigger} />
          case 'edit':
            return <ChainsEditor key={`edit-${chainId}`} chainId={chainId} refreshTrigger={refreshTrigger} />
          case 'library':
          default:
            return (
              <ChainsLibrary
                refreshTrigger={refreshTrigger}
                onChainDeleted={handleChainDeleted}
              />
            )
        }
      }

    return (
        <>

<Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Chains</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
              Chains are a collection of adversary emulation steps (Tests) that your teamserver will server to it's connected agents.
            </PageHeader.Description>
            <PageHeader.Actions>
              <Hidden when={['narrow']}>
              <Button
              variant='primary'
              data-testid='trigger-button'
              onClick={handleCreateNewChain} 
            >
                New chain
            </Button>
              </Hidden>
              <Hidden when={['regular', 'wide']}>
              <Button
              variant='primary'
              data-testid='trigger-button'
              onClick={handleCreateNewChain} 
            >
                New
            </Button>
              </Hidden>
            </PageHeader.Actions>
          </PageHeader>
        </Box>
        <Box>
          {renderContent()}
        </Box>
    </>
    )
}