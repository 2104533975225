import { useCallback } from 'react'
import { Box, Text, IconButton, useConfirm } from '@primer/react'
import { Link } from 'react-router-dom'
import { Icons } from '@/components/icons'
import { formatDistanceToNow } from 'date-fns'
import { deleteChain, updateChainStatus } from '@/lib/calls'
import { toast } from 'react-hot-toast'
import { CustomToast } from '@/components/custom-toast'


export default function ChainCard({ chain, onChainDeleted }) {
    const confirm = useConfirm()

    const onButtonClick = useCallback(async () => {
      if (
        await confirm({
          title: 'Are you sure?',
          content: `This action cannot be undone. Are you sure you want to continue?`,
          confirmButtonContent: 'Delete it!',
          confirmButtonType: 'danger',
        })
      ) {
        handleSubmitDeleteTest()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirm]) 

    const handleSubmitDeleteTest = async () => {
        try {
          await deleteChain(chain.id)
          toast.custom(t => <CustomToast message='Chain deleted' type='success' />)
          if (onChainDeleted) {
             onChainDeleted()
           }
     
        } catch (error) {
          toast.custom(t => (
            <CustomToast message='Error could not delete chain' type='error' />
          ))
          console.error('Error deleting chain:', error)
        } finally {
        }
      } 

    const handleSubmitRestartChain = async () => {
        try {
          await updateChainStatus(chain.id, 'restart')
          toast.custom(t => <CustomToast message='Chain status updated' type='success' />)
          if (onChainDeleted) {
             onChainDeleted()
           }
     
        } catch (error) {
          toast.custom(t => (
            <CustomToast message='Error could not update chain status' type='error' />
          ))
          console.error('Error updating chain status chain:', error)
        } finally {
        }
      } 

      const handleSubmitPauseChain = async () => {
        try {
          await updateChainStatus(chain.id, 'pause')
          toast.custom(t => <CustomToast message='Chain status updated' type='success' />)
          if (onChainDeleted) {
             onChainDeleted()
           }
     
        } catch (error) {
          toast.custom(t => (
            <CustomToast message='Error could not update chain status' type='error' />
          ))
          console.error('Error updating chain status chain:', error)
        } finally {
        }
      } 


    function getColorCircle(color) {
        return (
            <Box
            sx={{
                backgroundColor: color,
                borderColor: color,
                width: 14,
                height: 14,
                borderRadius: 10,
                margin: 'auto',
                borderWidth: '1px',
                borderStyle: 'solid',
            }}
            />
        )
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            gap: 2,
            p: 4,
            border: '1px solid',
            borderColor: 'border.default',
            justifyContent: 'space-between',
            borderRadius: 2,
            height: '100px',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'center',
                }}>
                    <Box>
                        {chain.status === 'done' 
                            ? getColorCircle('success.fg') 
                            : chain.status === 'pause'
                                ? getColorCircle('fg.muted')
                                : getColorCircle('attention.fg')
                        }
                    </Box>
                    <Text>{chain.name}</Text>
                </Box>
                <Text sx={{
                    fontSize: '12px',
                    fontWeight: 'semibold',
                }} color="fg.muted">Created {formatDistanceToNow(chain.created_at)} ago</Text>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
            }}>
                <IconButton size='small' variant='default' onClick={handleSubmitRestartChain}>
                    <Icons.Sync />
                </IconButton>

                <IconButton size='small' variant='default' onClick={handleSubmitPauseChain}>
                    <Icons.Columns />
                </IconButton>

<Link to={`?tab=edit&chain_id=${chain.id}`}>
                <IconButton size='small'>
                    <Icons.Pencil />
                </IconButton>
</Link>
<IconButton size='small' variant='danger' icon={Icons.Trash} onClick={onButtonClick} />
            </Box>

        </Box>
    )
}