import { API_ROUTE } from '@/lib/constants'
import { Button, Box, Text, Link } from '@primer/react'
import { Icons } from '@/components/icons'
import Logo from '@/components/ui/logo'

export function LoginPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        width: '100%',
        height: '100vh',
        // backgroundColor: 'danger.fg',
        // backgroundColor: 'canvas.overlay',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'canvas.inset',
      }}
    >
      <Box
        sx={{
          marginTop: ['100px', '100px', '100px'],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Logo variant='color' size={32} />
          <Text fontSize={4}>Welcome back!</Text>
          <Text fontSize={2} sx={{ color: 'fg.muted' }}>
            Sign in to continue
          </Text>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
            mt: 4,
          }}
        >
          {true && (
            <Button
              as='a'
              href={`${API_ROUTE}/auth/google/authorize`}
              variant='default'
              size='medium'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                width: '300px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Text>Continue with</Text> <Icons.Google size={14} />
              </Box>
            </Button>
          )}
          <Button
            as='a'
            href={`${API_ROUTE}/auth/github/authorize`}
            variant='default'
            size='medium'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              width: '300px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Text>Continue with</Text>
              <Icons.Github size={14} />
            </Box>
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              mb: '250px',
              mt: 3,
              fontSize: 'small',
            }}
          >
            <Text>By signing in, you agree to our </Text>
            <Link href='https://intrudergroup.com/terms' as='a'>
              Terms of Service
            </Link>
            <Text> and </Text>
            <Link href='https://intrudergroup.com/privacy' as='a'>
              Privacy policy
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
