import React from 'react'
import { createBrowserRouter, redirect } from 'react-router-dom'
import { authProvider } from '@/lib/auth'
import { AuthProvider } from '@/contexts/AuthContext'
import { RolesPage } from '@/components/roles-page'
import { TeamPage } from '@/components/team-page'
import { TestsPage } from '@/components/tests-page'
import { ChainsPage } from '@/components/chains-page'
import { TeamserversPage } from '@/components/teamservers-page'
import { DownloadsPage } from '@/components/downloads-page'
import { LogsPage } from '@/components/logs-page'

import { ProtectedRoute } from '@/components/protected-route' // Import the ProtectedRoute component
import { ChangeProjectPage } from '@/components/change-project-page'
import { ProjectPage } from '@/components/project-page'

// import Logout from '@/components/logout' // Import the Logout component
import { LoginPage } from '@/components/login-only-oauth'
// import { LoginPageBeta } from '@/components/login-beta'
import { DashboardLayout } from '@/components/dashboard-layout'
import { DashboardPage } from '@/components/dashboard-page'
import { NotFoundPage } from '@/components/not-found-page' // Import the new component
import { AccountSettingsPage } from '@/components/account-settings-page'
import { ApiKeysPage } from '@/components/developers-page'

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <ProtectedRoute />
      </AuthProvider>
    ),
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      </AuthProvider>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
        title: 'Dashboard',
        loader: () => {
          document.title = 'Dashboard'
          return null
        },
      },

      {
        path: 'tests',
        element: <TestsPage />,
        title: 'Tests',
        loader: () => {
          document.title = 'Tests'
          return null
        },
      },
{
        path: 'chains',
        element: <ChainsPage />,
        title: 'Chains',
        loader: () => {
          document.title = 'Chains'
          return null
        },
      },

      {
        path: 'teamservers',
        element: <TeamserversPage />,
        title: 'Teamservers',
        loader: () => {
          document.title = 'Teamservers'
          return null
        },
      },
      {
        path: 'execution-logs',
        element: <LogsPage />,
        title: 'Execution logs',
        loader: () => {
          document.title = 'Execution logs'
          return null
        },
      },
      {
        path: 'downloads',
        element: <DownloadsPage />,
        title: 'Downloads',
        loader: () => {
          document.title = 'Downloads'
          return null
        },
      },

      {
        path: 'settings/account',
        element: <AccountSettingsPage />,
        title: 'Account settings',
        loader: () => {
          document.title = 'Account settings'
          return null
        },
      },
      {
        path: 'settings/team',
        element: <TeamPage />,
        title: 'Team management',
        loader: () => {
          document.title = 'Team management'
          return null
        },
      },
      {
        path: 'settings/roles',
        element: <RolesPage />,
        title: 'Roles & Permissions',
        loader: () => {
          document.title = 'Roles & Permissions'
          return null
        },
      },
      {
        path: 'settings/project',
        element: <ProjectPage />,
        title: 'Project',
        loader: () => {
          document.title = 'Project'
          return null
        },
      },
      {
        path: 'settings/developers/api',
        element: <ApiKeysPage />,
        title: 'Developers API',
        loader: () => {
          document.title = 'Developers API'
          return null
        },
      },

      // ... add more child routes as needed ...
    ],
  },
  {
    path: '/login',
    // element: <LoginPage />,
    element: <LoginPage />,
    loader: async () => {
      await authProvider.signin()
      // console.log("login loader")
      // console.log(authProvider);
      if (authProvider.isAuthenticated) {
        return redirect('/dashboard')
      }
      document.title = 'Sign in'
      return null
    },
  },
  {
    path: 'change-project',
    element: <ChangeProjectPage />,
    title: 'Change project',
    loader: async () => {
      if (authProvider.isAuthenticated) {
        return redirect('/dashboard')
      }

      document.title = 'Change project'
      return null
    },
  },

  {
    path: '/logout',
    // element: <Logout />,
    loader: async () => {
      await authProvider.signout()
      // Redirect to login after successful or failed logout attempt
      return redirect('/login')
    },
  },
  {
    path: '*', // This will match any route not matched above
    element: <NotFoundPage />, // Your 404 component
    loader: () => {
      document.title = 'Page not found'
      return null
    },
  },
])
