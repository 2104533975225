import { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text, Box, Button, UnderlineNav, PageHeader } from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { TestLibrary } from '@/components/test-library'
import { TestPublicLibrary } from '@/components/test-public-library'
import { TestEditor } from '@/components/test-editor'
import { Icons } from '@/components/icons'

export function TestsPage() {
  const [tab, setTab] = useState('library')
  const [testId, setTestId] = useState(null)
  const [refreshTrigger, setRefreshTrigger] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const tabParam = urlParams.get('tab')
    const testIdParam = urlParams.get('test_id')

    if (tabParam === 'edit' && testIdParam) {
      setTab('edit')
      setTestId(testIdParam)
    } else if (
      tabParam === 'public' ||
      tabParam === 'library' ||
      tabParam === 'create'
    ) {
      setTab(tabParam)
      setTestId(null)
    } else {
      setTab('library')
      setTestId(null)
    }
  }, [location])

  const handleTabChange = useCallback(
    newTab => {
      navigate(`?tab=${newTab}`)
      setTab(newTab)
      setTestId(null)
    },
    [navigate]
  )

  const handleCreateNewTest = useCallback(() => {
    navigate('?tab=create')
    setTab('create')
    setTestId(null)
  }, [navigate])

  const handleTestDeleted = useCallback(() => {
    setRefreshTrigger(prev => prev + 1)
  }, [])

  const renderContent = () => {
    switch (tab) {
      case 'create':
      case 'edit':
        return <TestEditor testId={testId} />
      case 'public':
        return <TestPublicLibrary refreshTrigger={refreshTrigger} />
      case 'library':
      default:
        return (
          <TestLibrary
            refreshTrigger={refreshTrigger}
            onTestDeleted={handleTestDeleted}
          />
        )
    }
  }

  return (
    <Box>

<Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Tests</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
              Supports MITRE, Adversary emulation and Redcanary Red Team Atomic
              file structure. 
            </PageHeader.Description>
            <PageHeader.Actions>
              <Hidden when={['narrow']}>
              <Button
            variant='primary'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={handleCreateNewTest}
          >
            <Text>Create new test</Text>
          </Button>
              </Hidden>
              <Hidden when={['regular', 'wide']}>
              <Button
            variant='primary'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={handleCreateNewTest}
          >
            <Icons.Import /> <Text>New</Text>
          </Button>
              </Hidden>
            </PageHeader.Actions>
          </PageHeader>
        </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          width: '100%',
          height: '100%',
          marginTop: 4,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          {tab !== 'create' && tab !== 'edit' && (
            <Box>
              <UnderlineNav aria-label='Tests'>
                <UnderlineNav.Item
                  aria-current={tab === 'library' ? 'page' : undefined}
                  onClick={() => handleTabChange('library')}
                >
                  Your library
                </UnderlineNav.Item>
                <UnderlineNav.Item
                  aria-current={tab === 'public' ? 'page' : undefined}
                  onClick={() => handleTabChange('public')}
                >
                  Public library
                </UnderlineNav.Item>
              </UnderlineNav>
            </Box>
          )}
          {renderContent()}
        </Box>
      </Box>
    </Box>
  )
}
