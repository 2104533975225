import { Box, IconButton, Label, Text } from '@primer/react'
import { formatDistanceToNow } from 'date-fns'

import { Icons } from '@/components/icons'

export default function TeamserverCard( { teamserver } ) {
    return (
        <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, border: '1px solid', borderColor: 'border.default', borderRadius: 2, p: 2, height: '50px' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                // gap: 3,
                // minHeight: '30px',
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'center',
                    marginBottom: 1,
                    justifyContent: 'space-between',
                    alignContent: 'center',
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                    <Label size='small' variant='attention'>
                        {teamserver.os === "linux" && (
                    <Icons.Linux style={{
                        width: 18,
                        height: 18,
                        marginRight: 2
                    }} />

                        )}
                        {teamserver.os === "macos" && (
                        <Icons.MacOS style={{
                            width: 30,
                            height: 30,
                            marginRight: 2
                        }} />

                        )}
                        {teamserver.os === "windows" && (
                        <Icons.Windows style={{
                            width: 14,
                            height: 14,
                            marginRight: 2
                        }} />
                        )}
                    </Label>
                    <Label variant='accent' size='small'>:{teamserver.listening_port}</Label>
                    <Label variant='success' size='small'>
                    <Icons.Device
                    sx={{
                        width: 14,
                        height: 14,
                        mr: 1,
                    }}
                    />
                    {teamserver.agents.length}
                    </Label>

<Text sx={{
                    fontSize: '11px',
                    fontWeight: 'semibold',
                }} color="fg.subtle">Last seen {formatDistanceToNow(teamserver.last_seen)} ago</Text>

                    </Box>
                    <IconButton variant='danger' disabled>
                        <Icons.Trash />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    </Box>
    )
}