import axios from 'axios'
import { API_ROUTE } from '@/lib/constants'
// import Cookies from 'js-cookie'

export async function patchMe(username) {
  // console.log('patch username: ', username)
  try {
    await axios.patch(
      `${API_ROUTE}/users/me`,
      {
        username: username,
      },
      {
        withCredentials: true, // to send cookies
      }
    )

    // console.log('patchMe response: ', response)

    window.location.reload()
  } catch (e) {
    // console.log('patchMe error: ', e)
    console.error(e)
    throw e
  }
}

export async function getFlashMessage() {
  // console.log('getFlashMessage: ')
  try {
    const response = await axios.get(`${API_ROUTE}/flash`, {
      withCredentials: true, // to send cookies
    })

    // console.log('getFlashMessage response: ', response)
    return response.data
  } catch (e) {
    // console.log('getFlashMessage error: ', e)
    console.error(e)
    throw e
  }
}

export async function putNewApiKey() {
  // console.log('putNewApiKey: ')
  try {
    const response = await axios.put(
      `${API_ROUTE}/users/api`,
      {},
      {
        // Empty data object
        withCredentials: true,
      }
    )
    // console.log('putNewApiKey response: ', response)
    return response.data
  } catch (e) {
    // console.log('putNewApiKey error: ', e)
    console.error(e)
    throw e
  }
}

export async function getApiKeys() {
  // console.log('get api keys: ')
  try {
    const response = await axios.get(`${API_ROUTE}/users/api`, {
      withCredentials: true, // to send cookies
    })

    // console.log('get api keys response: ', response)
    return response.data
  } catch (e) {
    // console.log('get api keys error: ', e)
    console.error(e)
    throw e
  }
}

export async function deleteApiKey(apiKeyId) {
  // console.log('delete api key: ', apiKeyId)
  try {
    await axios.delete(`${API_ROUTE}/users/api/${apiKeyId}`, {
      withCredentials: true, // to send cookies
    })

    // console.log('delete api key response: ', response)
  } catch (e) {
    // console.log('delete api key error: ', e)
    console.error(e)
    throw e
  }
}

export async function getMe() {
  try {
    const response = await axios.get(`${API_ROUTE}/users/me`, {
      withCredentials: true,
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function deleteMe() {
  try {
    await axios.delete(`${API_ROUTE}/users/me`, {
      withCredentials: true,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function createProject(name) {
  try {
    await axios.post(
      `${API_ROUTE}/projects`,
      {
        name: name,
      },
      {
        withCredentials: true,
      }
    )

    window.location.reload()
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function swapProject(projectId, noReload = false) {
  console.log('swap project: ', projectId)
  try {
    await axios.get(`${API_ROUTE}/projects/${projectId}/swap`, {
      withCredentials: true,
    })

    if (!noReload) {
      window.location.reload()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getProjects() {
  try {
    const response = await axios.get(`${API_ROUTE}/projects`, {
      withCredentials: true,
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getRoles(projectId) {
  try {
    const response = await axios.get(
      `${API_ROUTE}/projects/${projectId}/roles`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function createRole(
  projectId,
  name,
  read_only,
  edit_tests,
  edit_chains
) {
  try {
    const response = await axios.post(
      `${API_ROUTE}/projects/${projectId}/roles`,
      {
        name: name,
        read_only: read_only,
        edit_tests: edit_tests,
        edit_chains: edit_chains,
      },
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function updateRole(
  projectId,
  roleId,
  name,
  read_only,
  edit_tests,
  edit_chains
) {
  try {
    const response = await axios.patch(
      `${API_ROUTE}/projects/${projectId}/roles/${roleId}`,
      {
        name: name,
        read_only: read_only,
        edit_tests: edit_tests,
        edit_chains: edit_chains,
      },
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function deleteRole(projectId, roleId) {
  try {
    const response = await axios.delete(
      `${API_ROUTE}/projects/${projectId}/roles/${roleId}`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function inviteUser(projectId, email, roleId) {
  try {
    const response = await axios.post(
      `${API_ROUTE}/projects/${projectId}/invites`,
      {
        email: email,
        role_id: roleId,
      },
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getProject(projectId) {
  try {
    const response = await axios.get(`${API_ROUTE}/projects/me`, {
      withCredentials: true,
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function updateUserRole(projectId, userId, roleId) {
  try {
    const response = await axios.patch(
      `${API_ROUTE}/projects/${projectId}/team/${userId}/role/${roleId}`,
      {},
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function deleteUserFromProject(projectId, userId) {
  try {
    const response = await axios.delete(
      `${API_ROUTE}/projects/${projectId}/team/${userId}`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getProjectInvites(projectId) {
  try {
    const response = await axios.get(
      `${API_ROUTE}/projects/${projectId}/invites`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function deleteProjectInvite(projectId, inviteId) {

  console.log('delete project invite: ', projectId)
  try {
    const response = await axios.delete(
      `${API_ROUTE}/projects/${projectId}/invites/${inviteId}`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function handleProjectInvite(projectId, inviteId, action) {
  try {
    const response = await axios.get(
      `${API_ROUTE}/projects/${projectId}/invites/${inviteId}/${action}`,
      {
        withCredentials: true,
      }
    )

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function patchProject(projectId, name) {
  try {
    await axios.patch(
      `${API_ROUTE}/projects/${projectId}`,
      {
        name: name,
      },
      {
        withCredentials: true,
      }
    )

    window.location.reload()
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function deleteProject(projectId) {
  try {
    await axios.delete(`${API_ROUTE}/projects/${projectId}`, {
      withCredentials: true, // to send cookies
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getTests() {
  try {
    const response = await axios.get(`${API_ROUTE}/tests`, {
      withCredentials: true, // to send cookies
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getPublicTests() {
  try {
    const response = await axios.get(`${API_ROUTE}/publictests`, {
      withCredentials: true, // to send cookies
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function validateTest(testData) {
  try {
    const response = await axios.post(`${API_ROUTE}/validate/test`, testData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'text/plain',
      }
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function createTest(testType, testData) {
  try {
    const response = await axios.post(`${API_ROUTE}/tests/${testType}`, testData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'text/plain',
      }
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function patchTest(testType, testId, testData) {
  try {
    const response = await axios.patch(`${API_ROUTE}/tests/${testType}/${testId}`, testData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'text/plain',
      }
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getTest(testId) {
  try {
    const response = await axios.get(`${API_ROUTE}/tests/${testId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function importTest(testId) {
  try {
    const response = await axios.put(`${API_ROUTE}/tests/${testId}/import`, null, {
      withCredentials: true,
    })
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function deleteTest(testId) {
  try {
    await axios.delete(`${API_ROUTE}/tests/${testId}`, {
      withCredentials: true,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getChains() {
  try {
    const response = await axios.get(`${API_ROUTE}/chains`, {
      withCredentials: true, // to send cookies
    })

    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getChain(chainId) {
  try {
    const response = await axios.get(`${API_ROUTE}/chains/${chainId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}



export async function getTeamservers() {
  try {
    const response = await axios.get(`${API_ROUTE}/projects/me/teamservers`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function createChain(name, teamserverId, selectedTests) {
  try {
    const response = await axios.post(`${API_ROUTE}/chains`, {
      name: name,
      teamserver_id: teamserverId,
      tests: selectedTests,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function patchChain(chainId, name, teamserverId, selectedTests) {
  try {
    const response = await axios.patch(`${API_ROUTE}/chains/${chainId}`, {
      name: name,
      teamserver_id: teamserverId,
      tests: selectedTests,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteChain(chainId) {
  try {
    await axios.delete(`${API_ROUTE}/chains/${chainId}`, {
      withCredentials: true,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function updateChainStatus(chainId, action) {
  try {
    await axios.get(`${API_ROUTE}/chains/${chainId}/${action}`, {
      withCredentials: true,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function putNewTeamserverToken() {
  try {
    const response = await axios.put(
      `${API_ROUTE}/projects/me/teamservers/tokens`,
      {},
      {
        withCredentials: true,
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw e
  }
}

export async function getTeamserverTokens() {
  try {
    const response = await axios.get(`${API_ROUTE}/projects/me/teamservers/tokens`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteTeamserverToken(tokenId) {
  try {
    await axios.delete(`${API_ROUTE}/projects/me/teamservers/tokens/${tokenId}`, {
      withCredentials: true,
    });
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getLogs() {
  try {
    const response = await axios.get(`${API_ROUTE}/projects/me/execution-logs`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function deleteExecutionLogs() {
  try {
    await axios.delete(`${API_ROUTE}/projects/me/execution-logs`, {
      withCredentials: true,
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}