/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useAuth } from '@/contexts/AuthContext'
import {
  Box,
  PageHeader,
  // ProgressBar,
  // Tooltip,
  Spinner,
  Text,
  AvatarStack,
  Avatar,
  // Label,
} from '@primer/react'
// import { Hidden } from '@primer/react/drafts'
import KPICard from '@/components/ui/kpi-card.jsx'
import { getProject } from '@/lib/calls'

export function DashboardPage() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [project, setProject] = useState(null)
  
  const fetchProject = async () => {
    try {
      const project = await getProject()
      setProject(project)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProject()
  }, [])

  const { user } = useAuth()
  if (!user) {
    return <Spinner />
  }

  return (
    <>
      <Box sx={{}}>


    <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Dashboard</PageHeader.Title>
            </PageHeader.TitleArea>
          </PageHeader>
        </Box>

        {loading && <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}><Spinner /></Box>}

            {error && (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2,
                height: '150px',
            }}>
                <Text fontWeight="bold" fontSize={4}>An error occured</Text>
                <Text color="fg.muted">Please try again later.</Text>
            </Box>
            )}

            {!loading && !error && project && user && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                gap: 3,
              }}
            >
              {/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
                <KPICard
                  title='Team seats'
                  message={`${project?.data?.project_users.length}`}
                  // showProgressBar={true}
                  // progressBarItem={project?.data?.project_users.length}
                  // progressBarTotal={10}
                  itemColor='accent.fg'
                  totalColor='accent.muted'
                />
          </Box>
          )}


<Box
                sx={{
                  mt: 4,
                  width: ['100%', '100%', '100%'], // Responsive borderWidth
                  border: '1px solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  padding: 3,
                  display: 'flex',
                  height: '150px',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: 'bold',
                    color: 'fg.default',
                  }}
                >
                  Your team
                </Text>
                {!project?.data?.project_users && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner key={project?.data?.project_users.length}/>
                  </Box>
                )}

                {project?.data?.project_users && (
                  <AvatarStack size={{ narrow: 32, regular: 42, wide: 42 }}>
                    {project?.data?.project_users.map(member => (
                      <Avatar
                        key={member.id}
                        alt={member.email}
                        src={member.picture}
                      />
                    ))}
                  </AvatarStack>
                )}
              </Box>
      </Box>
    </>
  )
}
