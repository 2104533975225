import { useEffect, useState, useCallback } from 'react'
import {
  Text,
  Box,
  Heading,
  Button,
  FormControl,
  PageHeader,
  TextInput,
  Spinner,
  useConfirm,
} from '@primer/react'

import { useAuth } from '@/contexts/AuthContext'
import { authProvider } from '@/lib/auth'
import { patchMe, deleteMe } from '@/lib/calls'

import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'

export function AccountSettingsPage() {
  const { user } = useAuth()

  const confirm = useConfirm()

  const onButtonClick = useCallback(async () => {
    if (
      await confirm({
        title: 'Are you sure?',
        content: `We are sorry to see you go. This action will delete all organizations, cancel their subscription and delete all your data. Are you sure you want to continue?`,
        confirmButtonContent: 'Delete it!',
        confirmButtonType: 'danger',
      })
    ) {
      handleSubmitDeleteMe()
    }
  }, [confirm])

  const handleSubmitDeleteMe = async () => {
    try {
      await deleteMe()
      toast.custom(t => <CustomToast message='Bye' type='success' />)

      await authProvider.signout()

      // todo signout
    } catch (error) {
      toast.custom(t => (
        <CustomToast message='Error could not delete user' type='error' />
      ))
      console.error('Error deleting user:', error)
    } finally {
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleSubmit = async () => {
    if (validationResult === 'validName') {
      try {
        setIsLoading(true)
        await patchMe(value)
        toast.custom(t => <CustomToast message='User updated' type='success' />)
        setIsLoading(false)
        // Handle success - e.g., show a success message
      } catch (error) {
        setIsLoading(false)
        // Handle errors - e.g., show error message

        toast.custom(t => (
          <CustomToast message='Error updating user' type='error' />
        ))

        // console.log(error);
        console.error('Error updating user:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (value.length > 255) {
      setValidationResult('tooLong')
    } else {
      setValidationResult('validName')
    }
  }, [value])

  return (
    <>
      <Box sx={{}}>


      <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Account settings</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Description sx={{
              fontSize: 1,
              color: 'fg.muted',
            }}>
              Keep your account information up to date. 
            </PageHeader.Description>
          </PageHeader>
        </Box>
     
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              // backgroundColor: 'green'
            }}
          >
            {/* <EmptyState /> */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <FormControl required>
                <FormControl.Label>Username</FormControl.Label>
                <TextInput
                  sx={{ width: ['100%', '100%', '50%'] }}
                  placeholder={user.user.username}
                  value={value}
                  onChange={handleInputChange}
                />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Username cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'tooLong' && (
                  <FormControl.Validation variant='error'>
                    Username cannot be longer than 255 characters
                  </FormControl.Validation>
                )}
                {validationResult === 'validName' && (
                  <FormControl.Validation variant='success'>
                    Valid username
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a username (1 to 255 characters).
                </FormControl.Caption>
              </FormControl>
              <FormControl disabled>
                <FormControl.Label>First name</FormControl.Label>
                <TextInput
                  sx={{ width: ['100%', '100%', '50%'] }}
                  value={user.user.first_name}
                />
              </FormControl>
              <FormControl disabled>
                <FormControl.Label>Last name</FormControl.Label>
                <TextInput
                  sx={{ width: ['100%', '100%', '50%'] }}
                  value={user.user.last_name}
                />
              </FormControl>
              <FormControl disabled>
                <FormControl.Label>Email</FormControl.Label>
                <TextInput
                  sx={{ width: ['100%', '100%', '50%'] }}
                  value={user.user.email}
                />
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                }}
              >
                <Button
                  variant='primary'
                  disabled={isLoading}
                  onClick={() => {
                    handleSubmit()
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    width: '65px', // Set a fixed width
                  }}
                >
                  {isLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '47px',
                      }}
                    >
                      <Spinner size='small' />
                    </Box>
                  ) : (
                    <Text>Submit</Text>
                  )}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: 4,
                width: '100%',
              }}
            >
              <Heading
                as='h2'
                sx={{
                  fontSize: 4,
                  fontWeight: 'normal',
                  color: 'danger.fg',
                  mb: 2,
                }}
              >
                Danger zone
              </Heading>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'danger.emphasis',
                  borderRadius: 2,
                  p: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gap: 1,
                  }}
                >
                  <Text
                    sx={{
                      fontSize: 1,
                      fontWeight: 'bold',
                      color: 'danger.fg',
                    }}
                  >
                    Delete account
                  </Text>
                  <Text
                    sx={{
                      fontSize: 1,
                      color: 'fg.muted',
                    }}
                  >
                    Deleting your account will delete all your projects,
                    cancel subscription and delete all your data. If you
                    encounter issues deleting your account contact us at{' '}
                    <b>help@intrudergroup.com</b> for assistance.
                  </Text>
                </Box>
                <Button variant='danger' onClick={onButtonClick}>
                  Delete account
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
