import {
  ServerIcon,
  ColumnsIcon,
  LogIcon,
  CommandPaletteIcon,
  RelFilePathIcon,
  SparkleFillIcon,
  TerminalIcon,
  FileAddedIcon,
  BeakerIcon,
  ProjectTemplateIcon,
  PlusIcon,
  AppsIcon,
  MailIcon,
  FileZipIcon,
  UnreadIcon,
  CodeIcon,
  CopyIcon,
  SunIcon,
  MoonIcon,
  BookIcon,
  XIcon,
  LinkIcon,
  SearchIcon,
  BellIcon,
  PasskeyFillIcon,
  KeyIcon,
  GraphIcon,
  FileMediaIcon,
  DeviceDesktopIcon,
  DevicesIcon,
  CalendarIcon,
  PlayIcon,
  DownloadIcon,
  PersonIcon,
  CreditCardIcon,
  PeopleIcon,
  ShieldIcon,
  InfoIcon,
  RocketIcon,
  SignOutIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
  LogoGithubIcon,
  GearIcon,
  StopIcon,
  KebabHorizontalIcon,
  AlertIcon,
  VideoIcon,
  TriangleDownIcon,
  TrashIcon,
  SyncIcon,
  PencilIcon,
} from '@primer/octicons-react'
import { FaWindows, FaLinux } from 'react-icons/fa'
import { SiMacos, SiYaml } from 'react-icons/si'
import { Octicon } from '@primer/react'

export const Icons = {
  Close: function (props) {
    return <Octicon {...props} icon={XIcon} />
  },
  Add: function (props) {
    return <Octicon {...props} icon={PlusIcon} />
  },
  Bell: function (props) {
    return <Octicon {...props} icon={BellIcon} />
  },
  Graph: function (props) {
    return <Octicon {...props} icon={GraphIcon} />
  },
  FileMedia: function (props) {
    return <Octicon {...props} icon={FileMediaIcon} />
  },
  Devices: function (props) {
    return <Octicon {...props} icon={DevicesIcon} />
  },
  Device: function (props) {
    return <Octicon {...props} icon={DeviceDesktopIcon} />
  },
  DevicePortrait: function (props) {
    return (
      <Octicon
        {...props}
        sx={{ transform: 'rotate(90deg)' }}
        icon={DeviceDesktopIcon}
      />
    )
  },

  Calendar: function (props) {
    return <Octicon {...props} icon={CalendarIcon} />
  },
  Play: function (props) {
    return <Octicon {...props} icon={PlayIcon} />
  },
  Download: function (props) {
    return <Octicon {...props} icon={DownloadIcon} />
  },
  Person: function (props) {
    return <Octicon {...props} icon={PersonIcon} />
  },
  CreditCard: function (props) {
    return <Octicon {...props} icon={CreditCardIcon} />
  },
  People: function (props) {
    return <Octicon {...props} icon={PeopleIcon} />
  },
  Shield: function (props) {
    return <Octicon {...props} icon={ShieldIcon} />
  },
  Info: function (props) {
    return <Octicon {...props} icon={InfoIcon} />
  },
  Rocket: function (props) {
    return <Octicon {...props} icon={RocketIcon} />
  },
  SignOut: function (props) {
    return <Octicon {...props} icon={SignOutIcon} />
  },
  ArrowLeft: function (props) {
    return <Octicon {...props} icon={ChevronLeftIcon} />
  },
  ArrowRight: function (props) {
    return <Octicon {...props} icon={ChevronRightIcon} />
  },
  ArrowDown: function (props) {
    return <Octicon {...props} icon={ChevronDownIcon} />
  },
  ArrowUp: function (props) {
    return <Octicon {...props} icon={ChevronUpIcon} />
  },
  PassKey: function (props) {
    return <Octicon {...props} icon={PasskeyFillIcon} />
  },
  Check: function (props) {
    return <Octicon {...props} icon={CheckIcon} />
  },
  Github: function (props) {
    return <Octicon {...props} icon={LogoGithubIcon} />
  },
  Google: function (props) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='14'
        viewBox='0 0 24 24'
        width='14'
        {...props}
      >
        <path
          d='M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z'
          fill='#4285F4'
        />
        <path
          d='M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z'
          fill='#34A853'
        />
        <path
          d='M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z'
          fill='#FBBC05'
        />
        <path
          d='M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z'
          fill='#EA4335'
        />
        <path d='M1 1h22v22H1z' fill='none' />
      </svg>
    )
  },
  Gear: function (props) {
    return <Octicon {...props} icon={GearIcon} />
  },
  Stop: function (props) {
    return <Octicon {...props} icon={StopIcon} />
  },
  Menu: function (props) {
    return <Octicon {...props} icon={KebabHorizontalIcon} />
  },
  Alert: function (props) {
    return <Octicon {...props} icon={AlertIcon} />
  },
  Video: function (props) {
    return <Octicon {...props} icon={VideoIcon} />
  },
  TriangleDown: function (props) {
    return <Octicon {...props} icon={TriangleDownIcon} />
  },
  Trash: function (props) {
    return <Octicon {...props} icon={TrashIcon} />
  },
  Sync: function (props) {
    return <Octicon {...props} icon={SyncIcon} />
  },
  LinkIcon: function (props) {
    return <Octicon {...props} icon={LinkIcon} />
  },
  Copy: function (props) {
    return <Octicon {...props} icon={CopyIcon} />
  },
  BookIcon: function (props) {
    return <Octicon {...props} icon={BookIcon} />
  },
  Sun: function (props) {
    return <Octicon {...props} icon={SunIcon} />
  },
  Moon: function (props) {
    return <Octicon {...props} icon={MoonIcon} />
  },
  SearchIcon: function (props) {
    return <Octicon {...props} icon={SearchIcon} />
  },
  Pencil: function (props) {
    return <Octicon {...props} icon={PencilIcon} />
  },
  Apps: function (props) {
    return <Octicon {...props} icon={AppsIcon} />
  },
  Key: function (props) {
    return <Octicon {...props} icon={KeyIcon} />
  },
  Code: function (props) {
    return <Octicon {...props} icon={CodeIcon} />
  },
  Email: function (props) {
    return <Octicon {...props} icon={MailIcon} />
  },
  Unread: function (props) {
    return <Octicon {...props} icon={UnreadIcon} />
  },
  Zip: function (props) {
    return <Octicon {...props} icon={FileZipIcon} />
  },
  ProjectTemplate: function (props) {
    return <Octicon {...props} icon={ProjectTemplateIcon} />
  },
  Test: function (props) {
    return <Octicon {...props} icon={BeakerIcon} />
  },
  Server: function (props) {
    return <Octicon {...props} icon={ServerIcon} />
  },
  Windows: function (props) {
    return <FaWindows {...props} />
  },
  MacOS: function (props) {
    return <SiMacos {...props} />
  },
  Linux: function (props) {
    return <FaLinux {...props} />
  },
  Yaml: function (props) {
    return <SiYaml {...props} />
  },
  Import: function (props) {
    return <Octicon {...props} icon={FileAddedIcon} />
  },
  Columns: function (props) {
    return <Octicon {...props} icon={ColumnsIcon} />
  },
  Shell: function (props) {
    return <Octicon {...props} icon={TerminalIcon} />
  },
  CommandPalette: function (props) {
    return <Octicon {...props} icon={CommandPaletteIcon} />
  },
  RelFilePath: function (props) {
    return <Octicon {...props} icon={RelFilePathIcon} />
  },
  SparkleFill: function (props) {
    return <Octicon {...props} icon={SparkleFillIcon} />
  },
  Log: function (props) {
    return <Octicon {...props} icon={LogIcon} />
  },
}
