import { Box, Text, Label} from '@primer/react'
import { Icons } from '@/components/icons'
import {Link} from 'react-router-dom'

export default function LogCard({ session }) {
  function getColorCircle(color) {
    return (
        <Box
        sx={{
            backgroundColor: color,
            borderColor: color,
            width: 14,
            height: 14,
            borderRadius: 10,
            margin: 'auto',
            borderWidth: '1px',
            borderStyle: 'solid',
        }}
        />
    )
}
  return (
    <>

    <Box sx={{
      border: '1px solid',
      borderColor: 'border.default',
      borderRadius: 2,
      padding: 3,
      marginBottom: 3,
      display: 'flex',
      flexDirection: 'column',
      gap: 3
    }}>
      <Text as="h3" fontWeight="bold">Session ID: {session.session_id}</Text>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'start'
      }}>
        <Label variant='attention'>Agent ID: {session.agent_id}</Label>
        
        <Label size='small' variant='attention'>
                        {session.agent_os === "linux" && (
                    <Icons.Linux style={{
                        width: 18,
                        height: 18,
                        marginRight: 2
                    }} />

                        )}
                        {session.agent_os === "macos" && (
                        <Icons.MacOS style={{
                            width: 30,
                            height: 30,
                            marginRight: 2
                        }} />

                        )}
                        {session.agent_os === "windows" && (
                        <Icons.Windows style={{
                            width: 14,
                            height: 14,
                            marginRight: 2
                        }} />
                        )}
                    </Label>

                    <Label variant='success'>Teamserver ID: {session.teamserver_id}</Label>
        <Label variant='success'>
        {session.teamserver_os === "linux" && (
                    <Icons.Linux style={{
                        width: 18,
                        height: 18,
                        marginRight: 2
                    }} />

                        )}
                        {session.teamserver_os === "macos" && (
                        <Icons.MacOS style={{
                            width: 30,
                            height: 30,
                            marginRight: 2
                        }} />

                        )}
                        {session.teamserver_os === "windows" && (
                        <Icons.Windows style={{
                            width: 14,
                            height: 14,
                            marginRight: 2
                        }} />
                        )}
          
        </Label>
        <Label variant='success'>Teamserver listening port: {session.teamserver_listening_port}</Label>
      </Box>


      <Link to={`/dashboard/chains?tab=edit&chain_id=${session.chain_id}`} style={{ textDecoration: 'none' }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center'
      }}>

        <Box sx={{

        }}>
            {session.chain_status === 'done' 
                ? getColorCircle('success.fg') 
                : session.chain_status === 'pause'
                    ? getColorCircle('fg.muted')
                    : getColorCircle('attention.fg')
            }
        </Box>
        <Text sx={{
          color: 'fg.default',
          textDecoration: 'none'
        }}>{session.chain_name}</Text>
      </Box>

      </Link>

      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 3
       }}>
        {session.execution_logs.map(log => (
          <Box key={log.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              backgroundColor: 'canvas.subtle',
              overflow: 'auto',
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'border.default',
              height: '100%',
              padding: 3
            }}>

              {log.exit_code === 0 ? (
                <>
          <Text
              sx={{
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
               ~$ # {log.test_name}
              
              </Text>

              <Text
              sx={{
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
               ~$ # pid {log.pid}
              
              </Text>

              <Text
                sx={{
                  fontWeight: 'var(--text-codeInline-weight)',
                  fontSize: 'var(--text-codeInline-size)',
                  fontFamily: 'monospace'
                }}
                >~$ {log.command}
              </Text>
<Text
              sx={{
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
              {log.output === '' ? 'No output' : log.output}
              </Text>
              </>
              ) : (
                <>
            <Text
              sx={{
                color: 'danger.fg',
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
                ~$ # {log.test_name}
              
              </Text>

              <Text
              sx={{
                color: 'danger.fg',
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
                ~$ # pid {log.pid}
              
              </Text>

                <Text
                  sx={{
                    color: 'danger.fg',
                    fontWeight: 'var(--text-codeInline-weight)',
                    fontSize: 'var(--text-codeInline-size)',
                    fontFamily: 'monospace'
                  }}
                  >~$ {log.command}
                </Text>
<Text
              sx={{
                color: 'danger.fg',
                fontWeight: 'var(--text-codeInline-weight)',
                fontSize: 'var(--text-codeInline-size)',
                fontFamily: 'monospace'
              }}>
              {log.output === '' ? 'No output' : log.output}
              </Text>
              </>
              )}


              
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
    </>
  )
}