/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useRef, useState } from 'react'
import {
  Text,
  Box,
  Button,
  Dialog,
  FormControl,
  TextInput,
  Select,
  Tooltip,
  RelativeTime,
  PageHeader,
  AvatarToken,
  Label,
  ActionMenu,
  ActionList,
  IconButton,
  Spinner,
} from '@primer/react'
import { Hidden } from '@primer/react/drafts'
import { DataTable, Table } from '@primer/react/drafts'
import { Link } from 'react-router-dom'
import { Icons } from '@/components/icons'
import {
  inviteUser,
  updateUserRole,
  deleteUserFromProject,
  getProjectInvites,
  getProject,
  getRoles,
  deleteProjectInvite,
} from '@/lib/calls'
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '@/components/ui/card-primer'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'
import { useAuth } from '@/contexts/AuthContext'

export function TeamPage() {
  const { user } = useAuth()
  // alert(JSON.stringify(user))
  const [project, setProject] = useState(null)
  const [invites, setInvites] = useState([])
  const [reloadTable, setReloadTable] = useState(false)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await getProject(user.user.project_id)
        setProject(response.data)
        // console.log(user)
      } catch (err) {
        console.error('Error fetching project data:', err)
      }
    }

    const fetchInvites = async () => {
      try {
        const response = await getProjectInvites(user.user.project_id)
        setInvites(response.data)
      } catch (err) {
        console.error('Error fetching invites:', err)
      }
    }
    const fetchRoles = async () => {
      try {
        setIsLoading(true)
        // alert(JSON.stringify(user))
        const response = await getRoles(user.user.project_id)
        setRoles(response.data)
      } catch (error) {
        console.error('Error fetching roles', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchRoles()

    fetchProjectData()
    fetchInvites()
  }, [])

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await getProject(user.user.project_id)
        setProject(response.data)
        // console.log(user)
      } catch (err) {
        console.error('Error fetching project data:', err)
      }
    }
    const fetchInvites = async () => {
      try {
        const response = await getProjectInvites(user.user.project_id)
        setInvites(response.data)
      } catch (err) {
        console.error('Error fetching invites:', err)
      }
    }

    const fetchRoles = async () => {
      try {
        setIsLoading(true)
        // alert(JSON.stringify(user))
        const response = await getRoles(user.user.project_id)
        setRoles(response.data)
      } catch (error) {
        console.error('Error fetching roles', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchRoles()

    fetchProjectData()
    fetchInvites()
  }, [reloadTable])

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const buttonRef = useRef()
  const onDialogClose = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false)
    }
  }, [isLoading])
  const [selectedRoleId, setSelectedRoleId] = useState('')
  const [selectedRole, setSelectedRole] = useState('choose_role')

  const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false)
  const onDialogDeleteUserClose = useCallback(() => {
    if (!isLoading) {
      setIsOpenDeleteUser(false)
    }
  }, [isLoading])

  const [isOpenChangeUserRole, setIsOpenChangeUserRole] = useState(false)
  const onDialogChangeUserRoleClose = useCallback(() => {
    if (!isLoading) {
      setIsOpenChangeUserRole(false)
    }
  }, [isLoading])

  const [deletedUserId, setDeletedUserId] = useState('')
  const [projectId, setProjectId] = useState('')
  const [changedUserId, setChangedUserId] = useState('')

  // const [userRoleChanged, setUserRoleChanged] = useState(false);

  const [value, setValue] = useState('')
  const [validationResult, setValidationResult] = useState('')

  const [roleValidationResult, setRoleValidationResult] = useState('')

  const handleOpenDeleteUserDialog = (userId, projectId) => {
    // alert(userId);
    // console.log(userId)
    // alert(userId)
    setDeletedUserId(userId)
    setProjectId(projectId)
    setIsOpenDeleteUser(true)
  }

  const handleDeleteInvite = async (inviteId, projectId) => {

    console.log(projectId)

    try {
      await deleteProjectInvite(projectId, inviteId)
      toast.custom(t => <CustomToast message='Invite deleted' type='success' />)
      setReloadTable(!reloadTable)
    } catch (error) {
      toast.custom(t => <CustomToast message='Error deleting invite' type='error' />)
      setReloadTable(!reloadTable)
      console.error('Error deleting invite:', error)
    } finally {
      setReloadTable(!reloadTable)
    }
  }

  const handleOpenDeleteInvite = (row, projectId) => {
    handleDeleteInvite(row.id, projectId)
  }

  const handleSubmitDeleteUser = async () => {
    try {
      setIsLoading(true)
      await deleteUserFromProject(projectId, deletedUserId)
      toast.custom(t => <CustomToast message='User deleted' type='success' />)
      setIsOpenDeleteUser(false)
      setReloadTable(!reloadTable)
      setIsLoading(false)
      // Handle success - e.g., show a success message
    } catch (error) {
      // Handle errors - e.g., show error message
      setIsLoading(false)
      toast.custom(t => (
        <CustomToast message='Error deleting user' type='error' />
      ))

      console.error('Error deleting user:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenChangeUserRoleDialog = userId => {
    setChangedUserId(userId)
    setSelectedRole('choose_role')
    setSelectedRoleId('')
    setIsOpenChangeUserRole(true)
  }

  const handleChangeUserRole = event => {
    setSelectedRole(event.currentTarget.value)
    setSelectedRoleId(event.currentTarget.value)
    setRoleValidationResult('validSelection')
  }

  const handleSubmitChangeUserRole = async () => {
    if (roleValidationResult === 'validSelection') {
      handlePatchUserRole(selectedRoleId, changedUserId)
      // alert(selectedRoleId)
      // alert(changedUserId)
    }
  }

  const handleOpenInviteDialog = () => {
    setValue('')
    setSelectedRole('choose_role')
    setSelectedRoleId('')
    // setValidationResult('');
    setIsOpen(true)
  }

  const isValidEmail = email => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
    return emailRegex.test(email)
  }

  const handleRoleChange = event => {
    setSelectedRole(event.currentTarget.value)
    setSelectedRoleId(event.currentTarget.value)
    setRoleValidationResult('validSelection')
  }

  const handleInputChange = e => {
    setValue(e.currentTarget.value)
  }

  const handleSubmit = async (roleId, orgId) => {
    if (validationResult === 'validEmail') {
      try {
        if (roleId === '') {
          return toast.custom(t => (
            <CustomToast message='Please choose a role' type='error' />
          ))
        }

        setIsLoading(true)
        // console.log(roleId, orgId, value)
        await inviteUser(user.user.project_id, value, roleId)
        toast.custom(t => (
          <CustomToast message='Invitation sent' type='success' />
        ))
        // Handle success - e.g., show a success message
        setIsLoading(false)
        setReloadTable(!reloadTable)
        onDialogClose() // Close the dialog if successful
      } catch (error) {
        // Handle errors - e.g., show error message
        setIsLoading(false)
        toast.custom(t => (
          <CustomToast message='Error creating a new invitation' type='error' />
        ))

        // console.log(error)
        console.error('Error creating organization:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handlePatchUserRole = async (roleId, userId) => {
    try {
      if (roleId === '') {
        return toast.custom(t => (
          <CustomToast message='Please choose a role' type='error' />
        ))
      }

      setIsLoading(true)

      await updateUserRole(user.user.project_id, userId, roleId)
      setReloadTable(true)
      toast.custom(t => <CustomToast message='Role changed' type='success' />)
      setIsLoading(false)

      setIsOpenChangeUserRole(false)

      // setUserRoleChanged(true);
      // Handle success - e.g., show a success message
    } catch (error) {
      setIsLoading(false)
      // Handle errors - e.g., show error message

      toast.custom(t => (
        <CustomToast message='Error could not change role' type='error' />
      ))

      // console.log(error)
      console.error('Error changing role:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // console.log(selectedRoleId)
    if (selectedRoleId === '') {
      setRoleValidationResult('noSelection')
    } else {
      setRoleValidationResult('validSelection')
    }
    if (value.length === 0) {
      setValidationResult('empty')
    } else if (!isValidEmail(value)) {
      setValidationResult('wrongFormat')
    } else {
      setValidationResult('validEmail')
    }
  }, [value, selectedRoleId])

  return (
    <>
      <Box sx={{}}>

      <Box sx={{
        padding: 3
      }}>
          <PageHeader>
            <PageHeader.TitleArea>
              <PageHeader.Title as="h2">Team management</PageHeader.Title>
            </PageHeader.TitleArea>
            <PageHeader.Actions>
              <Hidden when={['narrow']}>
              <Button variant='default' onClick={() => handleOpenInviteDialog()}>
              Invite user
            </Button>
              </Hidden>
              <Hidden when={['regular', 'wide']}>
              <Button variant='default' onClick={() => handleOpenInviteDialog()}>
              Invite
            </Button>
              </Hidden>
            </PageHeader.Actions>
          </PageHeader>
        </Box>

        <Box
          sx={{
            display: 'flex',
            /* flexDirection: ["column", "row"], */
            width: '100%',
            height: '100%',
            marginTop: 4,
            // backgroundColor: 'canvas.default',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            {!project && !invites ? (
              <Card>
                <CardHeader>
                  <CardTitle></CardTitle>
                  <CardDescription></CardDescription>
                </CardHeader>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // height: '382px',
                    }}
                  >
                    <Spinner />
                  </Box>
                </CardContent>
                <CardFooter></CardFooter>
              </Card>
            ) : (
              <>
                <Card
                  sx={{
                    width: '100%',
                    // height: '450px',
                    overflow: 'auto',
                  }}
                >
                  <CardHeader>
                    <CardTitle>Users</CardTitle>

                    <CardDescription>Your team members</CardDescription>
                  </CardHeader>
                  <CardContent>
                    {project &&
                      project.project_users.map((member, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // justifyContent: '',
                            flexDirection: 'row',
                            mt: 2,
                            gap: 3,
                          }}
                        >
                          {/* <pre>{JSON.stringify(member, null, 2)}</pre> */}
                          <Box
                            as='form'
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              // flexDirection: 'row',
                              gap: 1,
                            }}
                          >
                            <AvatarToken
                              size='large'
                              text={`${member.email}`}
                              avatarSrc={member.picture}
                            />
                            {member.username && (
                              <Tooltip aria-label={member.username}>
                                <Label size='small' variant='accent'>
                                  {member.username
                                    ? member.username.length > 15
                                      ? member.username.slice(0, 12) + '...'
                                      : member.username
                                    : ''}
                                </Label>
                              </Tooltip>
                            )}
                            <Tooltip aria-label={member.role.name}>
                              <Label size='small' variant='success'>
                                {member.role.name
                                  ? member.role.name.length > 30
                                    ? member.role.name.slice(0, 30) + '...'
                                    : member.role.name
                                  : 'No role'}
                              </Label>
                            </Tooltip>
                          </Box>

                          <ActionMenu>
                            <ActionMenu.Anchor>
                              {/* <Button variant="invisible"><Icons.Menu /></Button> */}
                              <IconButton
                                variant='invisible'
                                icon={Icons.Menu}
                                aria-label=''
                              />
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay width='medium'>
                              <ActionList>
                                <ActionList.Item
                                  onSelect={() =>
                                    handleOpenChangeUserRoleDialog(
                                      member.user_id
                                    )
                                  }
                                >
                                  Change role
                                </ActionList.Item>
                                <ActionList.Divider />
                                <ActionList.Item
                                  variant='danger'
                                  onSelect={() =>
                                    handleOpenDeleteUserDialog(
                                      member.user_id,
                                      user.user.project_id
                                    )
                                  }
                                >
                                  Delete user
                                </ActionList.Item>
                              </ActionList>
                            </ActionMenu.Overlay>
                          </ActionMenu>
                        </Box>
                      ))}
                  </CardContent>
                  <CardFooter></CardFooter>
                </Card>
              </>
            )}
            {invites && invites.length > 0 && (
              <>
                <Card
                  sx={{
                    width: '100%',
                    // height: '450px',
                    marginTop: 4,
                    overflow: 'auto',
                  }}
                >
                  <CardHeader>
                    <CardTitle>Invites</CardTitle>

                    <CardDescription>Invites sent to users</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Table.Container>
                      {invites && (
                        <DataTable
                          aria-labelledby='roles'
                          aria-describedby='roles-subtitle'
                          data={invites}
                          columns={[
                            // {
                            //   header: 'ID',
                            //   field: 'id',
                            //   rowHeader: true,
                            // },
                            {
                              header: 'Email',
                              field: 'invitee_email',
                            },
                            {
                              header: 'Status',
                              field: 'status',
                            },
                            {
                              header: 'Expiration',
                              field: 'expires_at',
                              renderCell: row => {
                                return (
                                  <Label
                                    sx={{
                                      gap: 1,
                                    }}
                                  >
                                    Expire
                                    {new Date(row.expires_at) < new Date()
                                      ? 'd'
                                      : 's'}{' '}
                                    <RelativeTime
                                      date={new Date(row.expires_at)}
                                    />
                                  </Label>
                                )
                              },
                            },
                            {
                              header: 'Actions',
                              field: 'actions',
                              renderCell: row => {
                                return (
                                  <ActionMenu>
                                    <ActionMenu.Anchor>
                                      <IconButton
                                        variant='invisible'
                                        icon={Icons.Menu}
                                        aria-label=''
                                      />
                                    </ActionMenu.Anchor>
                                    <ActionMenu.Overlay width='medium'>
                                      <ActionList>
                                        {/* <ActionList.Item onSelect={() => alert('Copy role id clicked')}>
                                Copy role id
                              </ActionList.Item> */}
                                        <ActionList.Item
                                          variant='danger'
                                          onSelect={() =>
                                            handleOpenDeleteInvite(
                                              row,
                                              row.project_id
                                            )
                                          }
                                        >
                                          Delete invite
                                        </ActionList.Item>
                                      </ActionList>
                                    </ActionMenu.Overlay>
                                  </ActionMenu>
                                )
                              },
                            },
                          ]}
                        />
                      )}
                    </Table.Container>
                  </CardContent>
                  <CardFooter></CardFooter>
                </Card>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {isOpen && (
        <Dialog
          isOpen={isOpen}
          onDismiss={onDialogClose}
          returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Invite a new user</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              Invited users will have access to the resources you have assigned
              based on the selected role. Invitation expires in 30 minutes.
            </Text>
            <Box mt={3}>
              <FormControl required>
                <FormControl.Label>Email</FormControl.Label>
                <TextInput block value={value} onChange={handleInputChange} />
                {validationResult === 'empty' && (
                  <FormControl.Validation variant='error'>
                    Email cannot be empty
                  </FormControl.Validation>
                )}
                {validationResult === 'wrongFormat' && (
                  <FormControl.Validation variant='error'>
                    Email is invalid
                  </FormControl.Validation>
                )}
                {validationResult === 'validEmail' && (
                  <FormControl.Validation variant='success'>
                    Valid email
                  </FormControl.Validation>
                )}
                <FormControl.Caption>
                  Please enter a valid email address.
                </FormControl.Caption>
              </FormControl>
              <FormControl sx={{ mt: 2 }} required>
                <FormControl.Label>Role</FormControl.Label>
                {roles && roles.length === 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Text sx={{ color: 'attention.emphasis' }}>
                      {' '}
                      <Icons.Alert /> Additional role required. Go to
                    </Text>
                    <Link to='/dashboard/settings/roles'>
                      Roles & Permissions
                    </Link>
                  </Box>
                ) : (
                  <>
                    <Select
                      value={selectedRole}
                      onChange={handleRoleChange}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Select.Option value='choose_role' disabled>
                        Choose role
                      </Select.Option>
                      {roles &&
                        roles.map(role => (
                          <Select.Option key={role.id} value={role.id}>
                            {role.name}
                          </Select.Option>
                        ))}
                    </Select>
                    {roleValidationResult === 'noSelection' && (
                      <FormControl.Validation variant='error'>
                        Please select a role
                      </FormControl.Validation>
                    )}
                    {roleValidationResult === 'validSelection' && (
                      <FormControl.Validation variant='success'>
                        Role selected
                      </FormControl.Validation>
                    )}
                  </>
                )}
              </FormControl>
            </Box>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                onClick={onDialogClose}
                sx={{ mr: 1 }}
                variant='invisible'
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmit(selectedRoleId, user.user.project_id)
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button onClick={() => (handleSubmit(selectedRoleId, localProvider.user.current_org.org.id))} variant="primary">Send invitation</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenDeleteUser && (
        <Dialog
          isOpen={isOpenDeleteUser}
          onDismiss={onDialogDeleteUserClose}
          // returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Are you sure?</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              This action cannot be undone.
            </Text>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                variant='invisible'
                onClick={onDialogDeleteUserClose}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitDeleteUser()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '120px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Delete user</Text>
                )}
              </Button>
              {/* <Button type="submit" onClick={handleSubmitDeleteUser} variant="danger">Delete user</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}

      {isOpenChangeUserRole && (
        <Dialog
          isOpen={isOpenChangeUserRole}
          onDismiss={onDialogChangeUserRoleClose}
          // returnFocusRef={buttonRef}
          aria-labelledby='dialog-label'
        >
          <Dialog.Header>Change user role</Dialog.Header>
          <Box p={3}>
            <Text id='dialog-label' sx={{ color: 'fg.muted' }} fontSize={1}>
              User roles allow the user to access various resources.
            </Text>
            <FormControl required>
              <FormControl.Label>Role</FormControl.Label>
              <Select
                value={selectedRole}
                onChange={handleChangeUserRole}
                sx={{
                  width: '100%',
                }}
              >
                <Select.Option value='choose_role' disabled>
                  Choose role
                </Select.Option>
                {roles &&
                  roles.map(role => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
              </Select>
              {roleValidationResult === 'noSelection' && (
                <FormControl.Validation variant='error'>
                  Please select a role
                </FormControl.Validation>
              )}
              {roleValidationResult === 'validSelection' && (
                <FormControl.Validation variant='success'>
                  Role selected
                </FormControl.Validation>
              )}
            </FormControl>
            <Box display='flex' mt={3} justifyContent='flex-end'>
              <Button
                variant='invisible'
                onClick={onDialogChangeUserRoleClose}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={isLoading}
                onClick={() => {
                  handleSubmitChangeUserRole()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  width: '65px', // Set a fixed width
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '47px',
                    }}
                  >
                    <Spinner size='small' />
                  </Box>
                ) : (
                  <Text>Submit</Text>
                )}
              </Button>
              {/* <Button type="submit" onClick={handleSubmitChangeUserRole} variant="primary">Change role</Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
