let API_ROUTE
let COOKIE_DOMAIN

if (process.env.NODE_ENV === 'development') {
  API_ROUTE = 'http://localhost:42010/api/v1'
  COOKIE_DOMAIN = 'localhost'
} else if (process.env.NODE_ENV === 'staging') {
  API_ROUTE = 'https://staging.intrudergroup.com/api/v1'
  COOKIE_DOMAIN = '.intrudergroup.io'
} else {
  API_ROUTE = 'https://api.intrudergroup.com/api/v1'
  COOKIE_DOMAIN = '.intrudergroup.com'
}

export { API_ROUTE, COOKIE_DOMAIN }
