import { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  Text,
  Box,
  Spinner,
  Breadcrumbs,
  Button,
  FormControl,
} from '@primer/react'
import { toast } from 'react-hot-toast'
import { CustomToast } from './custom-toast'
import { createTest, getTest, patchTest, validateTest, importTest } from '@/lib/calls'

import CodeMirror from '@uiw/react-codemirror'
import { yaml } from '@codemirror/legacy-modes/mode/yaml'
import { StreamLanguage } from '@codemirror/language'
import { createTheme } from '@uiw/codemirror-themes'
import { tags as t } from '@lezer/highlight'

export function TestEditor() {
  const [testValue, setTestValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const isEditMode = searchParams.get('tab') === 'edit'
  const testId = searchParams.get('test_id')

  const isValidUUID = (uuid) => {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
    return uuidRegex.test(uuid)
  }

  const fetchTest = useCallback(async () => {
    if (isEditMode && isValidUUID(testId)) {
      setIsLoading(true)
      setError(null)
      try {
        const response = await getTest(testId)
        // console.log("respdata-editor", response.data.test)
        setTestValue(response.data.test.raw_yaml)
        // console.log("respdata", response.data)
        setIsPublic(response.data.test.is_public)
      } catch (err) {
        setError('Failed to fetch test. Please try again.')
      } finally {
        setIsLoading(false)
      }
    }
  }, [isEditMode, testId])

  useEffect(() => {
    if (isEditMode) {
      fetchTest()
    } else {
      setTestValue('')
      setIsPublic(false)
    }
  }, [fetchTest, isEditMode])

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)
    try {
      if (isPublic) {
        await importTest(testId)
        toast.custom(t => (
          <CustomToast message="Test imported successfully" type='success' />
        ))
      } else {
        const validationResponse = await validateTest(testValue)
        if (validationResponse.error === false) {
          const { test_type } = validationResponse.data
          if (isEditMode) {
            await patchTest(test_type, testId, testValue)
          } else {
            await createTest(test_type, testValue)
          }
          toast.custom(t => (
            <CustomToast message={isEditMode ? 'Test updated' : 'Test created'} type='success' />
          ))
        }
      }
      navigate('/dashboard/tests')
    } catch (err) {
      toast.custom(t => (
        <CustomToast message={isPublic ? 'Failed to import test' : 'Failed to submit test'} type='error' />
      ))
    } finally {
      setIsLoading(false)
    }
  }

  const darkTheme = createTheme({
    theme: 'dark',
    settings: {
      background: '#0d1117',
      backgroundImage: '',
      foreground: '#c9d1d9',
      caret: '#c9d1d9',
      selection: '#3b5070',
      selectionMatch: '#3b5070',
      lineHighlight: '#161b22',
      gutterBackground: '#0d1117',
      gutterForeground: '#484f58',
    },
    styles: [
      { tag: t.comment, color: '#8b949e' },
      { tag: t.variableName, color: '#c9d1d9' },
      { tag: [t.string, t.special(t.brace)], color: '#a5d6ff' },
      { tag: t.number, color: '#79c0ff' },
      { tag: t.bool, color: '#79c0ff' },
      { tag: t.null, color: '#79c0ff' },
      { tag: t.keyword, color: '#ff7b72' },
      { tag: t.operator, color: '#ff7b72' },
      { tag: t.className, color: '#ffa657' },
      { tag: t.definition(t.typeName), color: '#ffa657' },
      { tag: t.typeName, color: '#ffa657' },
      { tag: t.angleBracket, color: '#c9d1d9' },
      { tag: t.tagName, color: '#7ee787' },
      { tag: t.attributeName, color: '#79c0ff' },
    ],
  })

  const lightTheme = createTheme({
    theme: 'light',
    settings: {
      background: '#ffffff',
      backgroundImage: '',
      foreground: '#24292f',
      caret: '#24292f',
      selection: '#b4d5fe',
      selectionMatch: '#b4d5fe',
      lineHighlight: '#f6f8fa',
      gutterBackground: '#ffffff',
      gutterForeground: '#57606a',
    },
    styles: [
      { tag: t.comment, color: '#6e7781' },
      { tag: t.variableName, color: '#24292f' },
      { tag: [t.string, t.special(t.brace)], color: '#0a3069' },
      { tag: t.number, color: '#0550ae' },
      { tag: t.bool, color: '#0550ae' },
      { tag: t.null, color: '#0550ae' },
      { tag: t.keyword, color: '#cf222e' },
      { tag: t.operator, color: '#cf222e' },
      { tag: t.className, color: '#953800' },
      { tag: t.definition(t.typeName), color: '#953800' },
      { tag: t.typeName, color: '#953800' },
      { tag: t.angleBracket, color: '#24292f' },
      { tag: t.tagName, color: '#116329' },
      { tag: t.attributeName, color: '#0550ae' },
    ],
  })

  const [currentTheme, setCurrentTheme] = useState(lightTheme)

  // Function to get the current theme preference
  function getThemePreference() {
    return localStorage.getItem('themePreference') || 'day'
  }

  // Function to apply the theme
  function applyThemeToEditor(theme) {
    setCurrentTheme(theme)
  }

  // Function to set the theme
  function setTheme(preference) {
    const theme = preference === 'night' ? darkTheme : lightTheme
    applyThemeToEditor(theme)
  }

  // Initialize theme
  useEffect(() => {
    const preference = getThemePreference()
    setTheme(preference)

    // Add event listener for storage changes
    const handleStorageChange = event => {
      if (event.key === 'themePreference') {
        setTheme(event.newValue)
      }
    }
    window.addEventListener('storage', handleStorageChange)

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return (
      <Box>
        <Text>{error}</Text>
        <Button onClick={fetchTest}>Retry</Button>
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: '100%', height: '100%' }}>
          <Breadcrumbs sx={{ paddingLeft: 2 }}>
            <Link to='/dashboard/tests'><Breadcrumbs.Item href='#'>Tests</Breadcrumbs.Item></Link>
            <Breadcrumbs.Item href='#' selected sx={{ color: 'accent.fg', fontWeight: 'bold' }}>
              {isEditMode ? (isPublic ? 'Import test' : 'Edit test') : 'Create new test'}
            </Breadcrumbs.Item>
          </Breadcrumbs>
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <FormControl required>
              <FormControl.Label>YAML test file</FormControl.Label>
              <Box sx={{ p: 2, backgroundColor: 'canvas.default', borderRadius: 2, width: '100%' }}>
                <CodeMirror
                  extensions={[StreamLanguage.define(yaml)]}
                  value={testValue}
                  theme={currentTheme}
                  onChange={setTestValue}
                  height='400px'
                  readOnly={isPublic}
                />
              </Box>
            </FormControl>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', justifyContent: 'end' }}>
              <Button variant='primary' onClick={handleSubmit} disabled={isLoading}>
                {isEditMode ? (isPublic ? 'Import' : 'Update') : 'Submit'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
