import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  IconButton,
  Spinner,
  Breadcrumbs,
  useConfirm,
  Label,
} from '@primer/react'
import { Icons } from '@/components/icons'

import CodeMirror from '@uiw/react-codemirror'
import { yaml } from '@codemirror/legacy-modes/mode/yaml'
import { StreamLanguage } from '@codemirror/language'
import { createTheme } from '@uiw/codemirror-themes'
import { tags as t } from '@lezer/highlight'
import { formatDistanceToNow } from 'date-fns'
import { toast } from 'react-hot-toast'
import { CustomToast } from '@/components/custom-toast'
import { deleteTest, importTest } from '@/lib/calls'

export function TestCard({ test, onTestDeleted }) {
    // console.log("test testcard", test)

 const [testValue] = useState(() => {
  const lines = test.raw_yaml.split('\n');
  return lines.slice(0, 10).join('\n') + (lines.length > 10 ? '\n...' : '');
 });


 const [isPublic] = useState(() => {
  return test.is_public
 });

 const handleImportTest = async () => {
    try {
      await importTest(test.id)
      toast.custom(t => (
        <CustomToast message="Test imported successfully" type='success' />
      ))
    //   if (onTestImported) {
    //     onTestImported()
    //   }
    } catch (error) {
      toast.custom(t => (
        <CustomToast message='Error could not import test' type='error' />
      ))
      console.error('Error importing test:', error)
    }
  }

 const confirm = useConfirm()

 const onButtonClick = useCallback(async () => {
   if (
     await confirm({
       title: 'Are you sure?',
       content: `This action cannot be undone. Are you sure you want to continue?`,
       confirmButtonContent: 'Delete it!',
       confirmButtonType: 'danger',
     })
   ) {
     handleSubmitDeleteTest()
   }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [confirm])

 const handleSubmitDeleteTest = async () => {
   try {
     await deleteTest(test.id)
     toast.custom(t => <CustomToast message='Test deleted' type='success' />)
     if (onTestDeleted) {
        onTestDeleted()
      }

   } catch (error) {
     toast.custom(t => (
       <CustomToast message='Error could not delete test' type='error' />
     ))
     console.error('Error deleting test:', error)
   } finally {
   }
 }



  const darkTheme = createTheme({
    theme: 'dark',
    settings: {
      background: '#0d1117',
      backgroundImage: '',
      foreground: '#c9d1d9',
      caret: '#c9d1d9',
      selection: '#3b5070',
      selectionMatch: '#3b5070',
      lineHighlight: '#161b22',
      gutterBackground: '#0d1117',
      gutterForeground: '#484f58',
    },
    styles: [
      { tag: t.comment, color: '#8b949e' },
      { tag: t.variableName, color: '#c9d1d9' },
      { tag: [t.string, t.special(t.brace)], color: '#a5d6ff' },
      { tag: t.number, color: '#79c0ff' },
      { tag: t.bool, color: '#79c0ff' },
      { tag: t.null, color: '#79c0ff' },
      { tag: t.keyword, color: '#ff7b72' },
      { tag: t.operator, color: '#ff7b72' },
      { tag: t.className, color: '#ffa657' },
      { tag: t.definition(t.typeName), color: '#ffa657' },
      { tag: t.typeName, color: '#ffa657' },
      { tag: t.angleBracket, color: '#c9d1d9' },
      { tag: t.tagName, color: '#7ee787' },
      { tag: t.attributeName, color: '#79c0ff' },
    ],
  })

  const lightTheme = createTheme({
    theme: 'light',
    settings: {
      background: '#ffffff',
      backgroundImage: '',
      foreground: '#24292f',
      caret: '#24292f',
      selection: '#b4d5fe',
      selectionMatch: '#b4d5fe',
      lineHighlight: '#f6f8fa',
      gutterBackground: '#ffffff',
      gutterForeground: '#57606a',
    },
    styles: [
      { tag: t.comment, color: '#6e7781' },
      { tag: t.variableName, color: '#24292f' },
      { tag: [t.string, t.special(t.brace)], color: '#0a3069' },
      { tag: t.number, color: '#0550ae' },
      { tag: t.bool, color: '#0550ae' },
      { tag: t.null, color: '#0550ae' },
      { tag: t.keyword, color: '#cf222e' },
      { tag: t.operator, color: '#cf222e' },
      { tag: t.className, color: '#953800' },
      { tag: t.definition(t.typeName), color: '#953800' },
      { tag: t.typeName, color: '#953800' },
      { tag: t.angleBracket, color: '#24292f' },
      { tag: t.tagName, color: '#116329' },
      { tag: t.attributeName, color: '#0550ae' },
    ],
  })

  const [currentTheme, setCurrentTheme] = useState(lightTheme)

  // Function to get the current theme preference
  function getThemePreference() {
    return localStorage.getItem('themePreference') || 'day'
  }

  // Function to apply the theme
  function applyThemeToEditor(theme) {
    setCurrentTheme(theme)
  }

  // Function to set the theme
  function setTheme(preference) {
    const theme = preference === 'night' ? darkTheme : lightTheme
    applyThemeToEditor(theme)
  }

  // Initialize theme
  useEffect(() => {
    const preference = getThemePreference()
    setTheme(preference)

    // Add event listener for storage changes
    const handleStorageChange = event => {
      if (event.key === 'themePreference') {
        setTheme(event.newValue)
      }
    }
    window.addEventListener('storage', handleStorageChange)

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    <Box>
    <Breadcrumbs
      sx={{
        paddingLeft: 2,
      }}
    >
      <Breadcrumbs.Item href='#'>Tests</Breadcrumbs.Item>
      <Breadcrumbs.Item href='#'>{isPublic ? 'Public library' : 'Your library'}</Breadcrumbs.Item>
      <Link to={`?tab=edit&test_id=${test.id}`}>
      <Breadcrumbs.Item
        
        href='#'
        sx={{
          color: 'accent.fg',
          fontWeight: 'bold',
        }}
      >
        {test.name.length > 40 ? test.name.slice(0, 40) + '...' : test.name}
      </Breadcrumbs.Item>
</Link>
    </Breadcrumbs>
    <Box>
      <Label size='large' variant='secondary'>
        Created {formatDistanceToNow(test.created_at)} ago
      </Label>
    </Box>
  </Box>
  <Box
    sx={{
      display: 'flex',
      flexDirection: ['column', 'row'],
      gap: 1,
      // alignItems: 'center',
      alignItems: ['start'],
      justifyContent: 'start',
      marginTop: 2,
    }}
  >
    <Label size='large' variant='secondary'>
      File format:
      <Icons.Yaml
        style={{
          width: 14,
          height: 14,
          marginLeft: 5,
          marginRight: 5,
        }}
      />
      {test.test_type}
    </Label>
  </Box>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
      alignItems: 'center',
      marginTop: 1,
    }}
  ></Box>

  <Box
    sx={{
      p: 2,
      backgroundColor: 'canvas.default',
      borderRadius: 2,
      width: '100%',
    }}
  >
    {testValue ? (
        <CodeMirror
        extensions={[StreamLanguage.define(yaml)]}
        value={testValue}
        readOnly
        // editable={false}
        theme={currentTheme}
        onChange={e => {
            console.log(e)
        }}
        height='100%'
        />
    ) : (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Spinner size='small' />
        </Box>
    )}
  </Box>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 2,
    }}
  >
    {isPublic ? (
    <IconButton size='small' variant='primary' icon={Icons.Import} onClick={handleImportTest} />

    ) : (
        <>
            <Link to={`?tab=edit&test_id=${test.id}`}>
                <IconButton size='small' variant='default' icon={Icons.Pencil} />
            </Link>
            <IconButton size='small' variant='danger' icon={Icons.Trash} onClick={onButtonClick} />
        </>
    )}

  </Box>
  </>
)
}